import axios from 'axios'

const requestService = payload => {
  const options = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  }

  return new Promise((resolve, reject) => {
    axios(options)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          resolve(res)
        } else {
          reject(new Error('Error occured'))
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

const SubmitSurvey = (params = {}) => {
  return new Promise((resolve, reject) => {
    requestService({
      url: params.url,
      method: 'POST',
      data: params.payload,
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default {
  SubmitSurvey,
}
