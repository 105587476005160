import React from 'react'
import Utils from 'spd-mimo/components/common/utils'

const PaymentDetailWrap = ({ children }) => {
  return <div className="payment-details">{children}</div>
}

const PaymentDetailSection = ({ className, children }) => {
  return (
    <div className={`payment-details__wrap ${className}`}>
      <div className="payment-details__content">{children}</div>
    </div>
  )
}

const PaymentDetailStatus = ({ label, subLabel, status }) => {
  return (
    <>
      <div>
        <div className="detail-title">{label}</div>
        <div>{subLabel}</div>
      </div>
      <div className={`payment-status ${status ? 'paid' : 'unpaid'}`}>
        {status ? 'Paid' : 'Payment Failed'}
      </div>
    </>
  )
}

const PaymentDetailInvoice = ({ label, detail }) => {
  return (
    <div className="invoice-detail">
      <div>{label}</div>
      <div className="detail-title">{detail}</div>
    </div>
  )
}

const PaymentDetailSummary = ({ label, subLabel, amount }) => {
  return (
    <>
      <div className="detail-amount-label">
        <div>{label}</div>
        {subLabel && <div>{subLabel}</div>}
      </div>
      <div className="detail-amount">{Utils.priceFormat(amount, '$')}</div>
    </>
  )
}

const PaymentDetailNote = ({ message }) => {
  return <div className="payment-details__note">{message}</div>
}

PaymentDetailWrap.defaultProps = {
  children: null,
}

PaymentDetailSection.defaultProps = {
  className: '',
  children: null,
}

PaymentDetailStatus.defaultProps = {
  label: '',
  subLabel: '',
  status: true,
}

PaymentDetailInvoice.defaultProps = {
  label: '',
  detail: '',
}

PaymentDetailSummary.defaultProps = {
  label: '',
  subLabel: '',
  amount: '',
}

PaymentDetailNote.defaultProps = {
  message: '',
}

export default {
  Wrap: PaymentDetailWrap,
  Section: PaymentDetailSection,
  Status: PaymentDetailStatus,
  Invoice: PaymentDetailInvoice,
  Summary: PaymentDetailSummary,
  Note: PaymentDetailNote,
}
