import { Field, useFormikContext } from 'formik'
import classnames from 'classnames'
import ErrorBlock from './ErrorBlock'
import React from 'react'
import PropTypes from 'prop-types'

export default function InputField({ spec, children, note }) {
  const { errors, touched } = useFormikContext()
  const hasError = touched[spec.id] && errors[spec.id]
  const _InputField = classnames(
    'lm--formItem form-item field-input lm--formItem--inline',
    {
      'has-error': hasError,
      'is-disabled': spec.disabled,
    }
  )

  return (
    <div className={_InputField}>
      <div className="label lm--formItem-label">
        <label htmlFor={spec.id}>{spec.label}</label>
        {note}
      </div>
      <div className="control lm--formItem-control">
        <Field
          aria-label={spec.label}
          data-testid={spec.id}
          id={spec.id}
          name={spec.id}
          type={spec.type}
          placeholder={spec.placeholder}
          {...spec}
        />
        {children && <div className="field-content">{children}</div>}
        {hasError && <ErrorBlock name={spec.id} />}
      </div>
    </div>
  )
}

InputField.defaultProps = {
  note: null,
}

InputField.propTypes = {
  spec: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  note: PropTypes.element,
}
