import React from 'react'
import classnames from 'classnames'

/**
 * Component for backdrops eg. a black opaque overlay
 * when modal is active
 */
const Backdrop = props => {
  let __backdrop__ = classnames('app-backdrop')

  return (
    <div
      className={__backdrop__}
      onClick={props.onClickHandler}
      style={{
        ...props.style,
      }}
    />
  )
}

export default Backdrop
