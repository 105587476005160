import { push } from 'connected-react-router'
import { CLEAR_DATA } from './application'
// Actions
export const SELECT = 'cs/home/SELECT'

// Reducer
export const homeInitState = {
  applicationType: '',
  isAbleToStart: false,
}
export default function reducer(state = homeInitState, action) {
  const handler = {
    [SELECT]: selectHandler,
  }

  return handler.hasOwnProperty(action.type) ? handler[action.type]() : state

  function selectHandler() {
    return {
      ...state,
      applicationType: action.applicationType,
      isAbleToStart: !!action.applicationType,
    }
  }
}

// Action Creators
export const setApplicationType = applicationType => dispatch => {
  dispatch({ type: CLEAR_DATA })
  return dispatch({ type: SELECT, applicationType })
}

export const goRef = type => dispatch => {
  dispatch(push(`/applications/${type}`))
}
