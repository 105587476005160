import React from 'react'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import Button from 'spd-mimo/components/common/Button'

const isEmpty = obj => {
  const entries = Object.keys(obj)
  if (entries.length > 0) {
    return false
  }
  return true
}

const isSubmitDisabled = (errors, isValid, isSubmitting) => {
  if (isSubmitting) {
    return true
  }

  if (!isValid) {
    return true
  }

  if (!isEmpty(errors)) {
    return true
  }

  return false
}

const FormCTA = ({
  isMobile,
  isInline,
  fromLeft,
  isCenter,
  children,
  ...props
}) => {
  const _formCta = classnames('lm--formItem form-item form-cta field-cta', {
    'lm--formItem--inline': isInline,
    'from-right': !isCenter && !fromLeft,
    'from-left': !isCenter && fromLeft,
    'btn-container is-mobile': isMobile,
  })

  const { isValid, isSubmitting, errors } = useFormikContext()
  const renderChildren = () => {
    return React.Children.map(children, (child, index) => {
      const props = {
        key: `form-cta--btn-${index}`,
        ...child.props,
      }
      if (child.type === Button) {
        props.disabled = isSubmitDisabled(errors, isValid, isSubmitting)
      }
      if (child.type === Button && child.props.type === 'submit') {
        if (isSubmitting) {
          props.loading = true
        } else {
          props.loading = null
          delete props.loading
        }
      }

      return React.cloneElement(child, props)
    })
  }

  return (
    <div className={_formCta} {...props}>
      {renderChildren()}
    </div>
  )
}

FormCTA.defaultProps = {
  isCenter: true,
  fromLeft: false,
}

export default FormCTA
