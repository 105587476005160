import React from 'react'
import { connect } from 'react-redux'
import Maintenance from 'spd-mimo/components/Maintenance'

const MaintenancePage = ({ maintenance, type }) => {
  return (
    <>
      <main className="app-main app-page">
        <Maintenance
          maintenance={maintenance}
          type={type}
          title="Upgrading to serve you better"
        />
      </main>
      {type === 'conn-serv' && (
        <footer className="app-footer">
          <div className="app-footer__links">
            <a href="mailto:homeconnection@spgroup.com.sg">Contact Us</a>
            <a href={`/supply-connection/feedback`}>Feedback</a>
            <a
              href="https://www.spgroup.com.sg/wcm/connect/spgrp/e045da24-5af7-4022-9c35-38c309af627f/%5BInfo%5D+FAQs+on+Electricity+Supply+Connection+for+Landed+Residential+Premises.pdf?MOD=AJPERES"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </a>
          </div>
          <p className="app-footer__copyright">
            © {new Date().getFullYear()} Singapore Power Ltd. All Rights Reserved
          </p>
        </footer>
      )}
    </>
  )
}

const mapSateToProps = state => ({
  maintenance: state.ctx.maintenance,
})

export default connect(mapSateToProps)(MaintenancePage)
