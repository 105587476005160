import {
  evolve,
  flip,
  fromPairs,
  join,
  map,
  pick,
  pipe,
  prop,
  splitAt,
  toLower,
  toUpper,
} from 'ramda'

/**
 * Capitalize first letter of the string, others to LowerCase
 * @function
 * @type {function(string): string}
 */
const capitalizeFirstChar = pipe(
  splitAt(1),
  evolve({ 0: toUpper, 1: toLower }),
  join('')
)

const categoryMap = {
  open: 'Open Utilities Application Check',
  close: 'Close Utilities Application Check',
}

const gaBaseDto = {
  event: 'Application Check',
  eventCategory: '',
  eventAction: 'Result',
  eventLabel: '',
  errorMessage: '',
  elecStatus: '',
  waterStatus: '',
  gasStatus: '',
}

const transformStatus = pipe(
  map(
    pipe(
      pick(['type', 'status']),
      evolve({
        type: flip(prop)({
          electricity: 'elecStatus',
          water: 'waterStatus',
          gas: 'gasStatus',
        }),
        status: capitalizeFirstChar,
      }),
      Object.values
    )
  ),
  fromPairs
)
/**
 * transform data to Google Analytics DTO
 * @param {string} eventCategory
 * @param {object} statusResponse
 * @return {object}
 */
function success(eventCategory, statusResponse) {
  const status = transformStatus(statusResponse.utilities)
  return {
    ...gaBaseDto,
    eventCategory: categoryMap[eventCategory],
    eventLabel: capitalizeFirstChar(statusResponse.status), // Success|Failure
    ...status,
  }
}

function fail(eventCategory, err) {
  return {
    ...gaBaseDto,
    eventCategory: categoryMap[eventCategory],
    eventLabel: 'Fail',
    errorMessage: capitalizeFirstChar(err.message),
  }
}
const gaUtil = { success, fail }
export default gaUtil
