import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import home from './home'
import application from './application'
import ctx from './ctx'
import connectionService from 'spd-mimo/redux/modules/connection-service/reducer'

function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    ctx,
    home,
    application,
    connectionService,
  })
}
export default createRootReducer
