import React, { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SurveyAnswer from './Answer'

const QuestionContext = createContext()
const QuestionProvider = QuestionContext.Provider

const SurveyQuestion = ({
  id,
  question,
  type,
  isCrosswise,
  optional,
  children,
}) => {
  const _Question = classnames('cui-survey--questionItem', {
    'is-crosswise': isCrosswise,
  })

  const memoizedQuestionState = useMemo(
    () => ({
      questionId: id,
      questionName: id,
      answerType: type,
    }),
    [id, type]
  )

  const renderChildren = () => {
    return React.Children.map(children, (child, index) => {
      if (child.type === SurveyAnswer) {
        return React.cloneElement(child, {
          id: child.props.id ? child.props.id : `${id}-ans-${index + 1}`,
        })
      }
    })
  }

  return (
    <div className={_Question} id={id}>
      {question && (
        <div className="cui-survey--question">
          {question}
          {`${optional ? ' (Optional)' : ''}`}
        </div>
      )}
      {children && (
        <div className="cui-survey--answers">
          <QuestionProvider value={memoizedQuestionState}>
            {renderChildren()}
          </QuestionProvider>
        </div>
      )}
    </div>
  )
}

SurveyQuestion.displayName = 'CuiSurveyQuestion'

SurveyQuestion.Context = QuestionContext
SurveyQuestion.Provider = QuestionProvider

SurveyQuestion.propTypes = {
  id: PropTypes.string,
  question: PropTypes.string,
  isCrosswise: PropTypes.bool,
  optional: PropTypes.bool,
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, child => {
      if (child.type !== SurveyAnswer) {
        error = new Error(
          `${componentName} only accepts children of type SurveyAnswer. Please use Survey.Answer component instead.`
        )
      }
    })
    return error
  },
}

SurveyQuestion.defaultProps = {
  id: '',
  question: '[Your Question Here]',
  isCrosswise: true,
  optional: false,
}

export default SurveyQuestion
