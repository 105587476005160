import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { goRef, setApplicationType } from '../redux/modules/home'
import { applicationType } from '../constants'

export function Home(props) {
  function go() {
    props.go(props.home.applicationType)
  }

  return (
    <main className="app-main home col-sm-12">
      <section>
        <h1 className="app-title">Check Utilities Application Status</h1>
        <p>Tell us what application status do you like to check</p>
        <div className="choose-type">
          {Object.entries(applicationType).map(function([key, label]) {
            return (
              <button
                key={`choose-${key}`}
                className={`choice ${
                  props.home.applicationType === key ? 'selected' : 'false'
                }`}
                data-testid={`type-${key}`}
                onClick={props.setApplicationType.bind(null, key)}
              >
                <figure>
                  <img src={`/images/icon/utilities-${key}.svg`} alt={key} />
                </figure>
                <span data-testid={`${key}-type-btn`}>{label}</span>
              </button>
            )
          })}
        </div>
        <div className="remarks">
          <ul>
            <li>
              Status check for 'Open Utilities Account'
              <ul>
                <li>
                  Applies only for online applications submitted for residential
                  premises under personal name
                </li>
                <li>
                  Application ID is needed for checking open utilities account
                  status. You can find it in the Utilities Account Summary
                  email.
                </li>
              </ul>
            </li>
            <li>
              Status check is not available for electricity supply if
              <ul>
                <li>
                  you purchase electricity supply under Retailers or SP
                  wholesale plan
                </li>
                <li>
                  you are taking over utilities of a premises with electricity
                  supply from a retailer
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="start">
          <div className="lm--formItem-label" />
          <button
            className="btn--mimo lm--button lm--button--primary start-btn"
            onClick={go}
            disabled={props.home.isAbleToStart ? null : true}
          >
            Check Status
          </button>
        </div>
      </section>
    </main>
  )
}

Home.propTypes = {
  home: PropTypes.shape({
    applicationType: PropTypes.string,
    isAbleToStart: PropTypes.bool,
  }),
  router: PropTypes.object,
  setApplicationType: PropTypes.func,
  go: PropTypes.func,
}

function mapStateToProps(state) {
  return state
}

const mapDispatchToProps = {
  setApplicationType,
  go: goRef,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
