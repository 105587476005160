import {
  defaultTo,
  evolve,
  flip,
  identity,
  ifElse,
  map,
  omit,
  path,
  pipe,
  prop,
} from 'ramda'
import { error, sapState } from '../constants'
/**
 * @typedef {{id: string, postalCode: string, nric: string, captcha: string, applicationType: string}} ReferenceDTO
 * @typedef {{id: string, status: string, turnOnDate: string}} UtilityStatusDTO
 * @typedef {{status: string, application_id: string, account_no: string, premises_address: string, submitted_date: string, utilities: Array<UtilityStatusDTO>}} StatusResponse
 * @typedef {{ getStatus: getStatus }} CasperskyApi
 */

/**
 * Caspersky API client
 * @external AxiosStatic
 * @param {AxiosStatic} http
 * @param {Object} config
 * @param {string} config.url
 * @param {number} config.timeout
 * @returns {CasperskyApi}
 */
export default function CasperskyApi(http, { url, timeout }) {
  // Constructor
  const client = http.create({
    baseURL: url,
    timeout,
  })

  /**
   * handle AxiosError
   * @external AxiosError
   * @param {AxiosError} resError
   * @throws {Error}
   */
  function handleError(resError) {
    throw ifElse(
      prop('response'),
      pipe(path(['response', 'data']), data => ({
        name: 'CasperskyError',
        message: data.message || error.internalError,
      })),
      identity
    )(resError)
  }

  /**
   * Map SAP status to readable code
   * Ex: 01 -> received, 04 -> completed
   * @function
   * @params {string} sapCode
   * @return {string}
   */
  const statusMapper = pipe(flip(prop)(sapState), defaultTo(''))
  /**
   * Get application detail
   * @typedef getStatus
   * @param {ReferenceDTO} reference
   * @returns {Promise}
   */
  function getStatus(reference) {
    /** @type {Promise<StatusResponse>}*/
    return client.post(`/v1/status`, reference).then(
      pipe(
        prop('data'),
        evolve({
          appl_status: statusMapper,
          utilities: map(evolve({ status: statusMapper })),
        }),
        function(data) {
          data.application_id = data.application_id || data.account_no
          return data
        },
        omit(['account_no'])
      ),
      handleError
    )
  }
  /**
   * Get application maintenance status
   * @typedef getMaintenance
   * @returns {Promise}
   */
  function getMaintenance() {
    /** @type {Promise<StatusResponse>}*/
    return client.get(`/appconfig`)
  }
  return { getStatus, getMaintenance }
}
