import React from 'react'
import PropTypes from 'prop-types'
import SupportContact from '../common/SupportContact'
import { sapState, status } from '../../constants'
import ApplicationStatus from './ApplicationStatus'

const hasNullableUtil = Array.prototype.includes.bind([
  sapState['01'],
  sapState['02'],
])

export default function Detail(props) {
  const label = {
    open: {
      refId: 'Application ID',
      remark:
        'If you have submitted a request to change your turn on date, it will be reflected within 2 working days. If you need assistance, please chat with our customer service officer on the SP app.',
    },
    close: {
      refId: 'Account Number',
      remark:
        'If you have submitted a request to change your termination date, it will be reflected within 2 working days. If you need assistance, please chat with our customer service officer on the SP app.',
    },
  }
  const { data, type } = props
  const { utilities } = data
  const shouldShowUtilities = hasNullableUtil(data.status)
    ? Array.isArray(utilities) && utilities.length !== 0
    : true
  const info = [
    [label[type].refId, data.id],
    ['Premises Address', data.address],
  ]
  return (
    <div className="application-detail">
      <div className="detail_section">
        <div className="u-grid detail_section--title">
          <div className="col-md-4 col-sm-12 u-cell label">
            <h4>Application Information</h4>
          </div>
        </div>
        <div className="detail_section--content">
          {info.map(([label, data], id) => (
            <Info key={id} label={label} data={data} />
          ))}
          {shouldShowUtilities ? null : (
            <Info
              label="Application Status"
              data={<strong>{status[data.status]}</strong>}
            />
          )}
        </div>
        {shouldShowUtilities ? (
          <div className="u-grid detail_section--title">
            <div className="col-md-4 col-sm-12 u-cell label">
              <h4>Application Status</h4>
            </div>
          </div>
        ) : null}
      </div>
      {shouldShowUtilities ? (
        <ApplicationStatus type={type} utilities={utilities} />
      ) : null}
      <div className="notice">
        <p>
          * {label[type].remark} Otherwise, please contact us at{' '}
          <SupportContact />.
        </p>
      </div>
    </div>
  )
}
Detail.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    submittedAt: PropTypes.string,
    utilities: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        status: PropTypes.string,
        turnOnDate: PropTypes.string,
      })
    ),
  }),
}

function Info({ label, data }) {
  return (
    <div className="u-grid">
      <div className="col-md-4 col-sm-12 u-cell label u-text-muted">
        {label}
      </div>
      <div className="col-md-8 col-sm-12 u-cell data">{data}</div>
    </div>
  )
}
