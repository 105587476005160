import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import Reference from '../components/Reference'
import {
  fetchApplication,
  goHome,
  goRef,
  search,
} from '../redux/modules/application'
import ApplicationDetail from '../components/ApplicationDetail'

export function SearchApplication(props) {
  const labels = {
    open: {
      title: 'Open Utilities Account Application Status',
    },
    close: {
      title: 'Close Utilities Account Application Status',
    },
  }
  const { type } = useParams()
  const { path } = useRouteMatch()

  const label = labels[type]
  return (
    <main className="app-main search-application">
      <section className="page-title">
        <h1>{label.title}</h1>
      </section>
      <section>
        <Switch>
          <Route exact path={path}>
            <Reference
              reference={props.application.reference}
              applicationType={type}
              handleSubmit={props.handleSubmit.bind(null, type)}
              backHome={props.backHome}
            />
          </Route>
          <Route path={`${path}/:id`}>
            <ApplicationDetail
              {...props}
              type={type}
              fetchApplication={props.fetchApplication.bind(null, type)}
              back={props.back.bind(null, type)}
            />
          </Route>
        </Switch>
      </section>
    </main>
  )
}
SearchApplication.propTypes = {
  reference: PropTypes.object,
  handleSubmit: PropTypes.func,
  back: PropTypes.func,
  fetchApplication: PropTypes.func,
  backHome: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    application: state.application,
  }
}

const mapDispatchToProps = {
  handleSubmit: search,
  back: goRef,
  fetchApplication,
  backHome: goHome,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchApplication)
