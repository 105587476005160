import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

function ErrorBlock(props) {
  return (
    <div id={`error-${props.name}`} className="error-block">
      <ErrorMessage {...props} />
    </div>
  )
}

ErrorBlock.propTypes = {
  name: PropTypes.string,
}

export default ErrorBlock
