import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Icons from './Icons'
import ConfirmationDialog from './ConfirmationDialog'

class Button extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      confirm: false,
    }

    this.onClickHandler = props.onClickHandler

    if (typeof props.onClickHandler === 'string') {
      this.onClickHandler = window.__handlers[props.onClickHandler]
    }
  }

  _onClickHandler = e => {
    if (this.onClickHandler) {
      e.preventDefault()
      e.stopPropagation()
      // e.nativeEvent.stopImmediatePropagation()

      const { confirm: confirmProp, onClickHandlerArgs } = this.props
      if (confirmProp) {
        this.setState(prevState => ({
          ...prevState,
          confirm: true,
        }))
      } else {
        if (this.onClickHandler) {
          this.onClickHandler(e.nativeEvent, onClickHandlerArgs, this._element)
        }
      }
    }
  }

  _confirmationOkHandler = e => {
    const { onClickHandlerArgs, dataMethod } = this.props
    if (this.onClickHandler) {
      this.onClickHandler(e, [...onClickHandlerArgs, dataMethod], this._element)
    }
  }

  _confirmationCloseHandler = () => {
    this.setState(prevState => ({
      ...prevState,
      confirm: false,
    }))
  }

  _renderElement() {
    const {
      link,
      content,
      children,
      to,
      rel,
      className,
      variant,
      onClickHandler,
      disabled,
      type,
      loading,
      bare,
      styles,
      id,
    } = this.props
    let _to = to
    if (onClickHandler) {
      _to = ''
    }
    if (content && !children) {
      if (link) {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            id={id}
            ref={el => {
              this._element = el
            }}
            href={disabled ? '#' : _to}
            onClick={this._onClickHandler}
            dangerouslySetInnerHTML={{ __html: content }}
            rel={rel || null}
            className={`${className}`}
            type={type}
            style={styles}
            // data-method={dataMethod || null}
          />
        )
      } else {
        return (
          <button
            id={id}
            disabled={disabled}
            ref={el => {
              this._element = el
            }}
            onClick={this._onClickHandler}
            dangerouslySetInnerHTML={{ __html: content }}
            rel={rel || null}
            className={
              !bare
                ? `btn-mimo lm--button lm--button--${variant} ${className}`
                : `${className}`
            }
            type={type}
            style={styles}
            // data-method={dataMethod || null}
          />
        )
      }
    } else if (children && !content) {
      if (link) {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            id={id}
            ref={el => {
              this._element = el
            }}
            href={disabled ? '#' : _to}
            onClick={this._onClickHandler}
            // dangerouslySetInnerHTML={{__html:content}}
            rel={rel || null}
            className={`${className}${loading ? ' loading' : ''}`}
            type={type}
            style={styles}
            // data-method={dataMethod || null}
          >
            {loading && (
              <Icons.spinloader className="btn-loader" width={50} height={50} />
            )}
            <span>{children}</span>
          </a>
        )
      } else {
        return (
          <button
            id={id}
            disabled={disabled}
            ref={el => {
              this._element = el
            }}
            onClick={this._onClickHandler}
            // dangerouslySetInnerHTML={{__html:content}}
            rel={rel || null}
            className={
              !bare
                ? `btn--mimo lm--button lm--button--${variant} ${className}${
                    loading ? ' loading' : ''
                  }`
                : `${className}${loading ? ' loading' : ''}`
            }
            type={type}
            style={styles}
            // data-method={dataMethod || null}
          >
            {loading && (
              <Icons.spinloader className="btn-loader" width={50} height={50} />
            )}
            <span>{children}</span>
          </button>
        )
      }
    } else {
      return null
    }
  }

  render() {
    const { confirm } = this.state
    const { confirmTitle, confirmContent } = this.props

    return (
      <Fragment>
        {this._renderElement()}

        {confirm && (
          <ConfirmationDialog
            confirmOkHandler={this._confirmationOkHandler}
            confirmCancelHandler={this._confirmationCloseHandler}
            onCloseHandler={this._confirmationCloseHandler}
            onEscapeKeyDownHandler={this._confirmationCloseHandler}
            title={confirmTitle}
            content={confirmContent}
          >
            {confirmContent}
          </ConfirmationDialog>
        )}
      </Fragment>
    )
  }
}

Button.propTypes = {
  /**
   * if `true`, an anchor <a> element will be rendered
   */
  link: PropTypes.bool,
  /**
   * the URL to go to when component is clicked
   */
  to: PropTypes.string,
  /**
   * click handler if button <button> element is rendered,
   * function for normal handler, string if passing in the name of the function/handler
   */
  onClickHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * click handler args if type of `string` was passed in the onClickHandler
   */
  onClickHandlerArgs: PropTypes.array,
  /**
   * content of the component
   */
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.node,
  ]),
  /**
   * no default classnames will be added
   */
  bare: PropTypes.bool,
}

Button.defaultProps = {
  link: false,
  to: '/',
  onClickHandler: null,
  onClickHandlerArgs: [],
  content: '',
  confirmTitle: null,
  confirmContent: null,
  className: '',
  variant: 'primary',
  disabled: false,
  bare: false,
}

export default Button
