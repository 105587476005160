import { useReducer } from 'react'
import * as yup from 'yup'

const useReducerThunk = (reducer, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  let dispatcher = action => {
    if (typeof action === 'function') {
      action(dispatcher, state)
    } else {
      dispatch(action)
    }
  }
  return [state, dispatcher]
}

const createSchema = (schema, surveyItem) => {
  const { id, validationOptions = {} } = surveyItem
  const { type: validationType, validations = [] } = validationOptions

  if (!validationType) return schema

  if (!yup[validationType]) {
    return schema
  }

  let validator = yup[validationType]()
  validations.forEach(validation => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    if (typeof params[0] === 'object') {
      if (params[0].type === 'ref') {
        let ref = params[0]
        let _params = params.slice(1, params.length)
        _params.unshift(yup[ref.type](...ref.params))
        validator = validator[type](..._params)
      }
    } else {
      validator = validator[type](...params)
    }
  })
  schema[id] = validator

  return schema
}

export { useReducerThunk, createSchema }
