import React from 'react'
import classnames from 'classnames'

import { Field, useFormikContext } from 'formik'

import ErrorBlock from '../ErrorBlock'

const FormSelectOption = ({ spec, options, isInline }) => {
  const { errors, touched } = useFormikContext()
  const hasError = touched[spec.id] && errors[spec.id]
  const _FormSelectOption = classnames('lm--formItem field-select', {
    'is-inline': isInline,
    'has-error': hasError,
  })

  const renderOptions = () => {
    return options.map(option => {
      let op_v = option.value
      if (typeof option.value === 'string') {
        op_v = op_v.toLowerCase()
      }
      return (
        <option disabled={option.disabled} value={op_v} key={option.value}>
          {option.text}
        </option>
      )
    })
  }

  return (
    <div className={_FormSelectOption}>
      {spec.label && (
        <div className="lm--formItem-label label">{spec.label}</div>
      )}
      <div className="lm--formItem-control control">
        <Field {...spec} component="select">
          {renderOptions()}
        </Field>
        <ErrorBlock name={spec.name} />
      </div>
    </div>
  )
}

export default FormSelectOption
