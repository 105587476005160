import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { object, string } from 'yup'
import Page, { PageHeader } from 'spd-mimo/components/Page'
import PaymentDetails from 'spd-mimo/components/Payment/PaymentDetails'
import ConfirmationDialog from 'spd-mimo/components/common/ConfirmationDialog'
import Detail from 'spd-mimo/components/common/Detail'
import Loader from 'spd-mimo/components/common/Loader'
import Icons from 'spd-mimo/components/common/Icons'
import { Choices, Form, Button } from '@casper-ui/components'
import { PLATFORM } from 'spd-mimo/constants'
import help from '../../helpers'
import ConnServActions from 'spd-mimo/redux/modules/connection-service/actions'
import Utils from 'spd-mimo/components/common/utils'

const Payment = ({ connectionService, platform, isMobile, dispatch }) => {
  const [isShowReminder, setIsShowReminder] = useState(false)
  const history = useHistory()

  const form = useRef(null)

  const ValidationSchema = object().shape({
    PAYMENT_METHOD: string()
      .required()
      .label('Payment Method')
      .default([]),
  })

  const memoizedInitialValues = useMemo(() => {
    const { status } = connectionService.data
    return connectionService.data && status && status.payment
      ? {
          PAYMENT_METHOD: status.payment.payment_method.defaults || [],
        }
      : ValidationSchema.default()
  }, [ValidationSchema, connectionService.data])

  const submitPayment = () => {
    const pmt_method =
      form.current.values.PAYMENT_METHOD[0] === 'PYMT_CREDIT_CARD'
        ? 'CREDIT_CARD'
        : 'DEBIT_CARD'
    const payload = {
      applicationId: connectionService.data.application_id,
      quotationNo: connectionService.data.status.quotation.number,
      mode: pmt_method,
      amount: connectionService.data.status.quotation.payable,
      redirectUrl: `${window.location.href}${
        platform !== 'web' ? `?platform=${platform}` : ''
      }`,
      platform,
    }
    dispatch(ConnServActions.SUBMIT_PAYMENT(payload))
    if (platform === PLATFORM.kiosk) {
      dispatch(ConnServActions.RESET())
    }
  }

  const handleSubmit = value => {
    if (connectionService.data.status.paymentStatus === 'PENDING') {
      setIsShowReminder(true)
    } else {
      submitPayment()
    }
  }

  const handleBack = useCallback(() => {
    history.push(`/supply-connection/status`)
  }, [history])

  function handleErrContinue() {
    dispatch(ConnServActions.ERROR_CONTINUE())
    handleBack()
  }

  const handleReminderConfirm = act => () => {
    setIsShowReminder(false)
    if (act === 'ok') {
      submitPayment()
    } else {
      form.current.setSubmitting(false)
    }
  }

  const changePaymentMethod = value => {
    if (form.current) {
      form.current.setFieldValue(value.id, value.chosen)
    }
  }

  const proceed = useCallback(() => {
    if (connectionService.submit_payment_success) {
      const { enets_url, ...data } = connectionService.data.payment
      const dataPayload = JSON.parse(data.payload)
      data.payload = JSON.stringify(dataPayload)

      const _form = document.createElement('form')
      _form.action = enets_url
      _form.method = 'POST'
      const payloadField = document.createElement('input')
      payloadField.name = 'payload'
      payloadField.value = data.payload
      const apiKeyField = document.createElement('input')
      apiKeyField.name = 'apiKey'
      apiKeyField.value = data.api_key
      const hmacField = document.createElement('input')
      hmacField.name = 'hmac'
      hmacField.value = data.hmac

      _form.append(payloadField)
      _form.append(apiKeyField)
      _form.append(hmacField)
      document.body.append(_form)
      _form.submit()
    }
  }, [connectionService])

  useEffect(() => {
    if (
      connectionService.submit_payment_success ||
      connectionService.submit_payment_fail
    ) {
      if (form.current) {
        const timeout = setTimeout(() => {
          if (connectionService.submit_payment_success) {
            proceed()
          } else {
            form.current.setSubmitting(false)
          }
        }, 1000)

        return () => {
          clearTimeout(timeout)
        }
      }
    }
  }, [connectionService, proceed])

  useEffect(() => {
    const { data, get_payment_status } = connectionService
    const { application_id, payment } = data
    const {
      location: { search },
    } = history

    const queries = Utils.parseQueryParams(search)

    if (!application_id) {
      history.push(help.getLandingUrl(platform))
    } else if (
      queries?.applicationId &&
      !get_payment_status &&
      platform === PLATFORM.kiosk
    ) {
      dispatch(ConnServActions.KIOSK_PAYMENT_PROCESS(queries))
    } else if (
      payment &&
      payment.submitted_payment &&
      queries &&
      queries.txnRef
    ) {
      dispatch(ConnServActions.GET_PAYMENT_STATUS({ txnRef: queries.txnRef }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPaymentContent = () => {
    const { status, payment_status } = connectionService.data
    if (!status || !status.payment || connectionService.get_payment_status) {
      return <Loader> Processing... </Loader>
    } else if (connectionService.get_payment_status_success && payment_status) {
      return (
        <PaymentDetails
          paymentStatus={payment_status}
          quotation={status.quotation}
        />
      )
    }

    return (
      <section className="payment-container">
        <Form
          validateOnMount
          validationSchema={ValidationSchema}
          initialValues={memoizedInitialValues}
          onSubmit={handleSubmit}
          innerRef={form}
          contain={false}
        >
          <Form.Wrapper>
            <Detail.Node>
              <Detail.NodeItem>
                <Detail.Label> Application Number </Detail.Label>
                <Detail.Data>
                  {connectionService.data.application_id}
                </Detail.Data>
              </Detail.NodeItem>
              <Detail.NodeItem>
                <Detail.Label> Quotation Number </Detail.Label>
                <Detail.Data>{status.quotation.number}</Detail.Data>
              </Detail.NodeItem>
              <Detail.NodeItem>
                <Detail.Label>
                  <span className="cui-label multi-title">Payment Method</span>
                </Detail.Label>
                <Detail.Data>
                  <Choices.Choices
                    id={status.payment.payment_method.id}
                    defaultSelected={status.payment.payment_method.defaults}
                    onChangeHandler={changePaymentMethod}
                    lengthwise
                  >
                    {status.payment.payment_method.options.map((opt, idx) => (
                      <Choices.Choice
                        key={`single-choice--payment-method-${idx}`}
                        id={opt.id}
                        className={opt.icon && 'with-icon'}
                        title={
                          <>
                            {opt.icon && (
                              <img
                                className="cui-choice__btn__icon"
                                src={opt.icon}
                                alt={`${opt.text || opt.value} icon`}
                              />
                            )}
                            {opt.text || (opt.value && <h4>{opt.value}</h4>)}
                            {opt.description && (
                              <small>{opt.description}</small>
                            )}
                          </>
                        }
                      />
                    ))}
                  </Choices.Choices>
                </Detail.Data>
              </Detail.NodeItem>
              <Detail.NodeItem>
                <Detail.Label> Amount Payable </Detail.Label>
                <Detail.Data>
                  <b>{Utils.priceFormat(status.quotation.payable)}</b>
                </Detail.Data>
              </Detail.NodeItem>
            </Detail.Node>
            <Form.CTA
              style={
                isMobile
                  ? {
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      marginRight: 'auto',
                      maxWidth: '100%',
                      position: 'absolute',
                      left: '0',
                      bottom: '0%',
                      paddingLeft: '5%',
                      paddingRight: '5%',
                    }
                  : {}
              }
              isCenter={false}
            >
              <Button
                variant="outlined"
                type="button"
                onClickHandler={handleBack}
                className={isMobile ? 'is-mobile' : ''}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="solid"
                className={isMobile ? 'is-mobile' : ''}
              >
                Proceed to Pay
              </Button>
            </Form.CTA>
          </Form.Wrapper>
        </Form>
      </section>
    )
  }

  return (
    <Page platform={platform}>
      <PageHeader title="Payment" />
      {renderPaymentContent()}

      <ConfirmationDialog
        visible={
          (connectionService.get_status_fail ||
            connectionService.get_payment_status_fail ||
            connectionService.submit_payment_fail) &&
          !!connectionService.error
        }
        confirmOkHandler={handleErrContinue}
        confirmCancelHandler={handleErrContinue}
        processing={false}
        actionConfig={{
          ok: {
            label: 'Back',
          },
          cancel: false,
        }}
        content={
          <>
            <figure className="alert-icon">
              <Icons.fail width={isMobile ? 180 : 240} />
            </figure>
            {isMobile ? (
              <>
                {connectionService.error &&
                connectionService.error.length > 100 ? (
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: connectionService.error,
                    }}
                  />
                ) : (
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: connectionService.error,
                    }}
                  />
                )}
              </>
            ) : (
              <h3
                dangerouslySetInnerHTML={{ __html: connectionService.error }}
              />
            )}
          </>
        }
      />
      <ConfirmationDialog
        visible={!!isShowReminder}
        confirmOkHandler={handleReminderConfirm('ok')}
        confirmCancelHandler={handleReminderConfirm('cancel')}
        processing={false}
        actionConfig={{
          cancel: {
            label: 'Cancel',
          },
          ok: {
            label: 'Continue',
          },
        }}
        content={
          isMobile ? (
            <h4>
              Our System has detected a payment transaction which is pending.
              Please click CONTINUE if you wish to proceed with payment or
              CANCEL to terminate the application.
            </h4>
          ) : (
            <h3>
              Our System has detected a payment transaction which is pending.
              Please click CONTINUE if you wish to proceed with payment or
              CANCEL to terminate the application.
            </h3>
          )
        }
      />
    </Page>
  )
}

const mapStateToProps = state => ({
  connectionService: state.connectionService,
  platform: state.ctx.platform,
  isMobile: PLATFORM.mobile === state.ctx.platform,
})

export default connect(mapStateToProps)(Payment)
