/**
 * @constant
 * @type {number} http request timeout in milliseconds
 */
const timeout = 60000

/**
 * @typedef AppConfig
 */
const config = {
  api: {
    conn: {
      url: process.env.REACT_APP_API_BASE,
      timeout,
    },
  },
  vendor: {
    google: {
      recaptcha: process.env.REACT_APP_RECAPTCHA_KEY,
    },
  },
}

export default config
