import React, { Fragment, useState } from 'react'
import Icons from 'spd-mimo/components/common/Icons'
import ConfirmationDialog from './common/ConfirmationDialog'

export default function KioskButton() {
  const redirectUrl = sessionStorage.getItem('redirectUrl')
  const [isVisible, setVisible] = useState(false)
  return (
    <div>
      <button
        className="btn--mimo lm--button lm--button--secondary"
        onClick={() => setVisible(true)}
      >
        End Session
      </button>
      <ConfirmationDialog
        visible={isVisible}
        confirmOkHandler={() => setVisible(false)}
        confirmCancelHandler={() => window.open(redirectUrl, '_self')}
        processing={false}
        actionConfig={{
          ok: {
            label: 'continue',
            variant: 'outlined',
          },
          cancel: {
            label: 'yes, exit now',
          },
        }}
        content={
          <Fragment>
            <figure className="alert-icon">
              <Icons.leave width={150} />
            </figure>
            <h3>End session and return to main menu?</h3>
          </Fragment>
        }
      />
    </div>
  )
}
