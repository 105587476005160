import React from 'react'

import Icons from './Icons'

const ResponseCard = ({ type, title, children }) => {
  return (
    <section className="responsecard">
      <figure className="responsecard-hero">
        {type === 'success' && <Icons.success width={204} height={153} />}
      </figure>
      <h2> {title} </h2>
      <div className="responsecard-content">{children}</div>
    </section>
  )
}

export default ResponseCard
