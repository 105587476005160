import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Home from './Home'
import MaintenancePage from './MaintenancePage'
import { connect, Provider } from 'react-redux'
import SearchApplication from './SearchApplication'
import ConnectionService from './ConnectionService'
import { Logo } from '@casper-ui/components'
import { PLATFORM } from 'spd-mimo/constants'
import KioskButton from 'spd-mimo/components/KioskButton'
import { checkMaintenance, initPlatform } from 'spd-mimo/redux/modules/ctx'
import Icons from '../components/common/Icons'

function Root({
  history,
  store,
  checkMaintenance,
  initPlatform,
  isKiosk,
  isMobile,
  maintenance,
}) {
  const [loading, setLoading] = useState(true)

  const isSPSInternalDomain =
    window.location.hostname === 'mimo.up.spdigital.sg' || window.location.hostname === 'mimo.spdigital.sg'

  useEffect(() => {
    initPlatform(history.location.search)
    if (!isSPSInternalDomain) {
      checkMaintenance().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [checkMaintenance, history.location.search, initPlatform])

  const renderMaintenanceCmp = (Cmp, type) => {
    if (loading) {
      return (
        <div className='root-loader'>
          <Icons.spinloader
            width={70}
            height={70}
            color={['#00B0B2']}
            shouldRotate
          />
        </div>
      )
    }
    if (maintenance[type] && maintenance[type].active) {
      return <MaintenancePage type={type} />
    } else {
      return <Cmp type={type} />
    }
  }

  const renderLogo = (history, isMobile) => {
    const isSCP = history.location.pathname.includes('/supply-connection')
    if (!isSCP) {
      return <Logo.SPgroup />
    }
    if (!isMobile) {
      return <Logo.SPgroup />
    }
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <header className="app-header">
          {renderLogo(history, isMobile)}
          {isKiosk && <KioskButton />}
        </header>
        <Switch>
          <Route
            exact
            path="/"
            component={() => renderMaintenanceCmp(Home, 'mimo')}
          />
          <Route
            path="/applications/:type"
            component={() => renderMaintenanceCmp(SearchApplication, 'mimo')}
          />
          <Route
            path={'/home-connection'}
            render={() => <Redirect to={'/supply-connection'} />}
          />
          <Route
            path="/supply-connection"
            component={() =>
              renderMaintenanceCmp(ConnectionService, 'conn-serv')
            }
          />
        </Switch>
      </ConnectedRouter>
    </Provider>
  )
}

const mapStateToProps = state => ({
  maintenance: state.ctx.maintenance,
  isKiosk: PLATFORM.kiosk === state.ctx.platform,
  isMobile: PLATFORM.mobile === state.ctx.platform,
})

const mapDispatchToProps = {
  checkMaintenance,
  initPlatform,
}

export default connect(mapStateToProps, mapDispatchToProps)(Root)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}
