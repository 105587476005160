import axios from 'axios'
import moment from 'moment'
import formatCurrency from 'format-currency'
import { error as constError } from '../../constants'

const INACTIVITY_THRESHOLD = 1000 * 120 // 2mins

function noop() {}

function objToFormData(payload) {
  if (!payload) return null
  const keys = Object.keys(payload)
  let result
  if (keys.length > 0) {
    result = new FormData()
    keys.forEach(key => {
      result.append(key, payload[key])
    })
  }
  return result
}

const request = (payload, shouldHaveAuth = false) => {
  const options = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  }
  if (shouldHaveAuth) {
    const token = window.sessionStorage.getItem('sps_cs:token')
    options.headers['Authorization'] = `Bearer ${token}`
  }
  return new Promise((resolve, reject) => {
    axios(options)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          resolve(res)
        } else {
          reject(new Error(res.data.status))
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

const GRAPHQL_DEFAULT_HEADERS = {
  // 'Accept': 'application/json',
  'Content-Type': 'application/json',
}

const graphQLRequest = (
  { headers, ...restPayload },
  query,
  variables,
  shouldHaveAuth = true
) => {
  const options = {
    ...restPayload,
    headers: {
      ...GRAPHQL_DEFAULT_HEADERS,
      ...headers,
    },
    validateStatus: function(status) {
      return status >= 200 && status < 300
    },
  }
  if (shouldHaveAuth) {
    const token = window.sessionStorage.getItem('sps_cs:token')
    options.headers['Authorization'] = `Bearer ${token}`
  }
  return new Promise((resolve, reject) => {
    axios({
      ...options,
      data: {
        query,
        variables,
      },
    })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        const errObj = {}
        if (err.response.status >= 400) {
          let error_description = ''
          switch (err.response.status) {
            case 401:
              error_description = 'You are not authorized for this request'
              break
            default:
              error_description = `An error has occurred. Please try again later.`
          }
          errObj.errors = [
            {
              error: err.response.data,
              status: err.response.status,
              error_description,
            },
          ]
        }
        reject(errObj)
      })
  })
}

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

const isEmpty = obj => {
  const entries = Object.keys(obj)
  if (entries.length > 0) {
    return false
  }
  return true
}

const hasEmptyValues = obj => {
  if (typeof obj !== 'object') return false
  const keys = Object.keys(obj)
  let result = 0
  keys.forEach(o => {
    if (Array.isArray(obj[o]) && obj[o].length === 0) {
      result += 1
    } else if (obj[o] === '') {
      result += 1
    } else if (!obj[o]) {
      result += 1
    }
  })
  return result === keys.length
}

const uniqueArrayOfObjects = (arrayOfObjects, by = 'index') => {
  return arrayOfObjects.filter((obj, index, self) => {
    return (
      index ===
      self.findIndex(t => {
        return t[by] === obj[by]
      })
    )
  })
}

const parseQueryParams = (queryStr = '') => {
  if (queryStr === '') return null
  const search = queryStr.split('?')
  const queryParams = {}
  if (search.length > 0) {
    const queries = search[1].split('&')
    if (queries.length > 0) {
      queries.forEach(q => {
        const _query = q.split('=')
        if (_query.length > 0) {
          queryParams[_query[0]] = _query[1]
        }
      })
    }
  }
  return queryParams
}

const transformListObject = (list = [], options = {}) => {
  if (list.length === 0) return []
  if ((!options.input && !options.output) || !options.input || !options.output)
    return []

  return list.map((item, i) => {
    let result = {}
    options.output.forEach((outp, j) => {
      result[outp] = item[options.input[j]]
    })
    return result
  })
}

const parseStrTemplate = (template, data = {}, replaceChar = {}) => {
  const keys = Object.keys(data)
  const chars = Object.keys(replaceChar)
  let result = template
  if (keys.length === 0) {
    return result
  }

  keys.forEach(key => {
    let re = new RegExp(`{${key}}`, 'g')
    let d = data[key]
    if (chars.length > 0) {
      chars.forEach(c => {
        let reC = new RegExp(`${c}`, 'g')
        d = d.replace(reC, replaceChar[c])
      })
    }
    result = result.replace(re, d)
  })
  return result
}

const formatDate = (date, format = 'DD MMM YYYY') => {
  return moment(date).format(format)
}

const parseDate = (
  date,
  format = 'DD MMM YYYY',
  timezone = 'Asia/Singapore'
) => {
  if (timezone) {
    return moment(date)
      .tz(timezone)
      .format(format)
  }
  return moment(date).format(format)
}

const roundDecimalBy = (num = 0, by = 1) => {
  // let pow = Math.pow(10, by)
  // return parseFloat(Math.round(num * pow) / pow, pow)

  let rounded = parseFloat(num).toFixed(parseInt(by, 10))
  let result = parseFloat(rounded)

  return result
}

const checkIfValIsNum = v => {
  return Number.isInteger(parseInt(v))
}

const transformWithZero = (v, padOffset) => {
  if (v.length < 2) {
    if (v.charAt(0) !== '0' && checkIfValIsNum(v.charAt(0))) {
      return `0${v}`
    } else {
      return v.slice(padOffset)
    }
  }
  if (v.length > 2) {
    if (v.charAt(0) === '0' && checkIfValIsNum(v.charAt(0))) {
      let sliceIt = true
      for (let i = 1; i < v.length; i++) {
        if (v.charAt(i) && !checkIfValIsNum(v.charAt(i))) {
          sliceIt = false
        }
      }
      if (sliceIt) {
        return v.slice(padOffset + 1)
      }
      return v
    }
  }
  return v
}

const trailWithZero = (v, joining = '/') => {
  let padOffset = -v.length
  if (v === '') {
    return ''
  }
  let vJoin = v.split(joining)
  if (vJoin.length > 1) {
    let vSplit = ''
    vJoin.forEach((vJ, vIndex) => {
      let r = transformWithZero(vJ, padOffset)
      if (r) {
        vSplit += `${r}`
      }
      if (vIndex < vJoin.length - 1) {
        vSplit += `/`
      }
    })
    return vSplit
  }

  let res = transformWithZero(v, padOffset)
  if (res) {
    return res
  }
  return v.slice(padOffset)
}

const groupThem = (
  source = [],
  by,
  options = { shape: ['fields', 'label'] }
) => {
  if (!by) return source
  const result = {}
  by.forEach(grp => {
    const grpItems = source.filter(item => {
      return item.group && item.group === grp.id
    })
    if (options) {
      result[grp.id] = result[grp.id] || {}
      if (options.shape) {
        options.shape.forEach(key => {
          if (key === 'fields') {
            result[grp.id][key] = grpItems
          } else {
            result[grp.id][key] = grp[key]
          }
        })
      }
    } else {
      result[grp.id] = grpItems
    }
  })
  return result
}

const transformToDataObject = (source = [], options = {}, model) => {
  if (source.length === 0) return null
  const result = {}

  const sessionData = window.sessionStorage.getItem('sps_cs:fields')
  let modelled
  if (sessionData) {
    modelled = mapToModel(JSON.parse(sessionData), model)
  }

  const attachValue = (item, key, index) => {
    if (modelled) {
      if (modelled[key] || modelled[key] === '') {
        result[key] = modelled[key]
      } else {
        result[key] = item.value
      }
    } else if (options.params && options.params.length > 0) {
      if (options.params.length > 1) {
        result[key] = result[key] || {}
        options.params.forEach(param => {
          if (param === 'value' && index !== -1) {
            result[key] = item[param][index]
          } else {
            result[key] = item[param]
          }
        })
      } else {
        if (options.params[0] === 'value' && index !== -1) {
          result[key] = item[options.params[0]][index]
        } else {
          result[key] = item[options.params[0]]
        }
      }
    }
  }

  source.forEach((item, idx) => {
    let key = idx
    if (item.id) {
      if (Array.isArray(item.id) && item.id.length > 1) {
        item.id.forEach((k, i) => {
          attachValue(item, k, i)
        })
      } else {
        key = item.id
        attachValue(item, key, -1)
      }
    } else {
      attachValue(item, key, -1)
    }
  })
  return result
}

let time
const inactivity = (cb = noop, thresh = INACTIVITY_THRESHOLD) => {
  document.onmousemove = resetInactivityTimer
  document.onkeypress = resetInactivityTimer
  document.onmousemove = resetInactivityTimer

  document.onmousemove = resetInactivityTimer
  document.onmousedown = resetInactivityTimer
  document.ontouchstart = resetInactivityTimer
  document.onclick = resetInactivityTimer
  document.onscroll = resetInactivityTimer

  function inactive() {
    cb()
  }

  function resetInactivityTimer() {
    window.clearTimeout(time)
    time = window.setTimeout(inactive, thresh)
  }

  resetInactivityTimer()
}

const disableInactivity = () => {
  document.onmousemove = noop
  document.onkeypress = noop
  document.onmousemove = noop

  document.onmousemove = noop
  document.onmousedown = noop
  document.ontouchstart = noop
  document.onclick = noop
  document.onscroll = noop
  window.clearTimeout(time)
  time = null
}

const normalizeByList = (list = [], normalizer = {}, options = {}) => {
  if (!normalizer) return list
  let result = list
  Object.keys(normalizer).forEach(key => {
    let grouped = list.filter(itm => itm.kind && itm.kind === key)
    let filteredGroup = grouped.filter((itm, idx) => {
      if (itm.choices) {
        let newChoices = itm.choices.filter(choice => {
          if (options.suffix) {
            let fltrd = []
            options.suffix.forEach(suf => {
              normalizer[key].forEach(n => {
                if (choice.id === `${n}${suf}`) {
                  fltrd.push(itm)
                }
              })
            })
            return fltrd.length > 0
          }
          return normalizer[key].some(n => n === choice.id)
        })
        itm.choices = newChoices
        return newChoices.length > 0
      }
      if (options.suffix) {
        let fltrd = []
        options.suffix.forEach(suf => {
          normalizer[key].forEach(n => {
            if (itm.id === `${n}${suf}`) {
              fltrd.push(itm)
            }
          })
        })
        return fltrd.length > 0
      }
      return normalizer[key].some(n => n === itm.id)
    })
    result = result.reduce((res, item) => {
      if (!item.kind || item.kind !== key) {
        res.push(item)
      }
      return res
    }, [])
    result = [...result, ...filteredGroup]
  })
  return result
}

const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const stringToId = str => {
  if (str === '') return str
  let re = new RegExp(' ', 'g')
  return str.toLowerCase().replace(re, '-')
}

const fieldsToModel = (path, dataModel, val) => {
  let k = path.split('.')
  let o = dataModel
  while (k.length) {
    let el = k.shift()
    if (k.length > 0) {
      if (!o.hasOwnProperty(el)) {
        o[el] = /^\d+$/.test(k[0]) ? [] : {}
      }
      o = o[el]
    } else {
      o[el] = val
    }
  }
  return dataModel
}

const mapToModel = (source, contract, resolver) => {
  if (typeof source !== 'object') {
    return null
  }

  if (!contract) {
    return source
  }

  let result = {}

  Object.keys(contract).forEach((item, idx) => {
    const field = item.split('.')
    if (field.length > 1) {
      let objKey = contract[item].split('.')
      if (objKey.length > 1) {
        let fieldValue
        field.forEach((fieldItem, itemIdx) => {
          if (itemIdx === 0) {
            fieldValue = source[fieldItem]
          } else {
            fieldValue = (fieldValue && fieldValue[fieldItem]) || null
          }
        })
        result = fieldsToModel(contract[item], result, fieldValue)
      } else {
        let fieldValue
        field.forEach((fieldItem, itemIdx) => {
          if (itemIdx === 0) {
            fieldValue = source[fieldItem]
          } else {
            if (
              fieldValue &&
              (fieldValue[fieldItem] || fieldValue[fieldItem] === '')
            ) {
              fieldValue = fieldValue[fieldItem]
              result = fieldsToModel(fieldItem, result, fieldValue)
            }
          }
        })
      }
    } else {
      let id = field[0]
      let objKey = contract[id].split('.')
      let value = (source && source[id]) || null
      if (objKey.length > 1) {
        result = fieldsToModel(contract[id], result, value)
      } else {
        let key = objKey[0]
        result[key] = value
      }
    }
  })

  if (resolver) {
    return {
      ...result,
      ...resolver(result),
    }
  }

  return result
}

const titleCase = str => {
  str = str.toLowerCase()
  str = str.split(' ')
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

const titleize = (str, separator) => {
  let sepRegex = new RegExp(separator, 'g')
  return titleCase(
    str
      .replace(sepRegex, ' ')
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
  )
}

const priceFormat = (
  value,
  code = 'SGD',
  inCents = false,
  removeDecimal = false
) => {
  let currency = code === 'SGD' ? 'S$' : '$'
  let result = ''
  let newVal = value
  if (inCents) {
    newVal = value / 100
  }
  result = formatCurrency(newVal, {
    code,
    format: '%s%v',
    symbol: currency,
    // locale: 'en-SG',
  })
  if (removeDecimal) {
    result = result.split('.')[0]
  }
  return result
}

const getSessionData = (type = 'data', resolver) => {
  const data = window.sessionStorage.getItem(`sps_cs:${type}`)
  let result
  if (data) {
    result = JSON.parse(data)
  }
  if (resolver) {
    result = resolver(result)
  }
  return result
}

const checkForErrorMessage = err => {
  let errMessage = ''

  if (err && err.message) {
    if (err.message === 'INVALID_APPLICATION_NUMBER') {
      errMessage = constError.invalid_app_number
    } else if (err.message === 'INVALID_APPLICATION_NUMBER_PAYMENT') {
      errMessage = constError.invalid_app_number_payment
    } else if (err.message === 'INVALID_MOBILE_NUMBER') {
      errMessage = constError.invalid_mobile_number
    } else {
      errMessage = err.message
    }
  } else {
    errMessage = 'An error occurred'
  }
  return errMessage
}

const updateSessionData = (payload, type = 'data') => {
  const data = getSessionData(type)
  let newData = {
    ...payload,
  }
  if (data) {
    newData = {
      ...data,
      ...payload,
    }
  }
  window.sessionStorage.setItem(`sps_cs:${type}`, JSON.stringify(newData))
}

const clearSessionData = () => {
  window.sessionStorage.removeItem('sps_cs:data')
  window.sessionStorage.removeItem('sps_cs:otp')
  window.sessionStorage.removeItem('sps_cs:fields')
  window.sessionStorage.removeItem('sps_cs:search')
}

export default {
  noop,
  getWindowSize,
  isEmpty,
  hasEmptyValues,
  uniqueArrayOfObjects,
  parseQueryParams,
  transformListObject,
  objToFormData,
  request,
  parseStrTemplate,
  formatDate,
  parseDate,
  roundDecimalBy,
  inactivity,
  disableInactivity,
  transformWithZero,
  trailWithZero,
  groupThem,
  transformToDataObject,
  normalizeByList,
  capitalize,
  stringToId,
  graphQLRequest,
  mapToModel,
  titleize,
  priceFormat,
  checkForErrorMessage,
  getSessionData,
  updateSessionData,
  clearSessionData,
}
