import Constants from './_constants'

export const initialState = {
  submitting: false,
  submit_success: false,
  submit_fail: false,
  error: null,
}

export default (state, action) => {
  switch (action.type) {
    case Constants.SUBMIT: {
      return {
        ...state,
        submitting: true,
        submit_success: false,
        submit_fail: false,
        error: null,
      }
    }

    case Constants.SUBMIT_SUCCESS: {
      return {
        ...state,
        submitting: false,
        submit_success: true,
        submit_fail: false,
        error: null,
      }
    }

    case Constants.SUBMIT_FAIL: {
      return {
        ...state,
        submitting: false,
        submit_success: false,
        submit_fail: true,
        error: action.payload,
      }
    }

    default:
      return {
        ...state,
      }
  }
}
