import { push } from 'connected-react-router'
import gaUtil from '../util/ga'
import { identity } from 'ramda'
import { axiosError, contact } from '../../constants'
// Actions
export const SUBMIT = 'cs/application/reference/SUBMIT'
export const FETCH_SUCCESS = 'cs/application/detail/FETCH_SUCCESS'
export const FETCH_ERROR = 'cs/application/detail/FETCH_ERROR'
export const FETCHING = 'cs/application/detail/FETCHING'
export const CLEAR_DATA = 'cs/application/CLEAR_DATA'
export const CLEAR_STATE = 'cs/application/CLEAR_STATE'

/**
 * Customize error messages
 * @function
 * @param {Error}
 * @return {string} human readable error message
 */
const errorMiddleware = (function() {
  const knownError = {
    AxiosError: function(msg) {
      return axiosError[msg] || msg
    },
    CasperskyError: identity,
  }
  return function(err) {
    return knownError.hasOwnProperty(err.name)
      ? knownError[err.name](err.message)
      : `An error has occurred: ${err.message}. Please contact us at ${contact.number}`
  }
})()
const initState = {
  reference: null,
  data: null,
  err: null,
  loading: false,
  maintenance: {},
}

// Reducers
export default function reducer(state = initState, action) {
  const handler = {
    [SUBMIT]: handleSearch,
    [FETCH_SUCCESS]: handleFetchSuccess,
    [FETCH_ERROR]: handleFetchError,
    [CLEAR_DATA]: clearData,
    [CLEAR_STATE]: clearState,
    [FETCHING]: fetching,
  }

  return handler.hasOwnProperty(action.type) ? handler[action.type]() : state

  function handleSearch() {
    return {
      ...state,
      reference: { ...action.form },
    }
  }

  function handleFetchSuccess() {
    return {
      ...state,
      data: { ...action.data },
      loading: false,
      err: null,
    }
  }

  function handleFetchError() {
    return {
      ...state,
      err: errorMiddleware(action.err),
      loading: false,
      data: null,
    }
  }

  function clearData() {
    return {
      ...initState,
    }
  }

  function clearState() {
    return {
      ...state,
      data: null,
      err: null,
    }
  }

  function fetching() {
    return {
      ...state,
      loading: true,
    }
  }
}

// Action creators
/**
 * Submit application reference
 * @param {string} type
 * @param {ReferenceDTO} form
 * @returns {function(...[*]=)}
 */
export function search(type, form) {
  return function(dispatch, getState, { api }) {
    if (form.captcha) {
      dispatch({ type: SUBMIT, form })
      dispatch(push(`/applications/${type}/${form.id}`))
      api.storage.state.set(getState())
    }
  }
}

export const goRef = type => dispatch => {
  dispatch({ type: CLEAR_STATE })
  dispatch(push(`/applications/${type}`))
}

export const goHome = function() {
  return function clearState(dispatch, _, { api }) {
    api.storage.state.clear()
    dispatch({ type: CLEAR_DATA })
    dispatch(push('/'))
  }
}

/**
 * @typedef {{id: string, address: string, submittedAt: string, utilities: Array<UtilityStatusDTO>}} StatusDTO
 */
/**
 * transform StatusResponse
 * @param {StatusResponse} statusResponse
 * @return {StatusDTO}
 */
function toStatusDTO(statusResponse) {
  return {
    id: statusResponse.application_id,
    address: statusResponse.premises_address,
    submittedAt: statusResponse.submitted_date,
    utilities: statusResponse.utilities,
    status: statusResponse.appl_status,
  }
}

/**
 * Fetch application status
 * @param {string} applicationType
 * @param {ReferenceDTO} reference
 * @returns {function(*, *, {api: *}): Promise<Object | Error>}
 */
export function fetchApplication(applicationType, reference) {
  return function(dispatch, getState, { api }) {
    dispatch({ type: FETCHING })
    return api.conn
      .getStatus({ ...reference, applicationType })
      .then(function(data) {
        api.ga.push(gaUtil.success(applicationType, data))
        dispatch({ type: FETCH_SUCCESS, data: toStatusDTO(data) })
      })
      .catch(function(err) {
        api.ga.push(gaUtil.fail(applicationType, err))
        dispatch({
          type: FETCH_ERROR,
          err: err.isAxiosError
            ? { name: 'AxiosError', message: err.message }
            : err,
        })
      })
  }
}
