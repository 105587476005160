import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FormTextarea from '../form/FormTextarea'
import FormSwitchRadio from '../form/FormSwitchRadio'
import FormSelectOption from '../form/FormSelectOption'

import Question from './Question'

const SurveyAnswer = ({ id, label, value, options, max, min }) => {
  const questionState = useContext(Question.Context) || {}
  const _Answer = classnames('cui-survey--answer')

  const renderAnswer = () => {
    if (questionState.answerType === 'textarea') {
      return (
        <FormTextarea
          isInline={false}
          spec={{
            minlength: min,
            maxlength: max,
            id,
            name: questionState.questionId,
            label,
          }}
        />
      )
    } else if (questionState.answerType === 'radio') {
      return (
        <FormSwitchRadio
          spec={{
            id,
            name: questionState.questionId,
            label,
            value,
          }}
        />
      )
    } else if (questionState.answerType === 'select') {
      return (
        <FormSelectOption
          spec={{
            id,
            name: questionState.questionId,
            label,
            // value,
          }}
          options={options}
        />
      )
    }
    return null
  }

  return (
    <div className={_Answer} id={`ansItem-${id}`}>
      {renderAnswer()}
    </div>
  )
}

SurveyAnswer.displayName = 'CuiSurveyAnswer'

SurveyAnswer.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  min: PropTypes.number,
  max: PropTypes.number,
}

SurveyAnswer.defaultProps = {
  id: '',
  name: '',
  label: null,
  value: '',
  options: [
    {
      id: 'answer-1',
      value: 'answer 1',
      disabled: false,
    },
  ],
  min: 0,
  max: 0,
}

export default SurveyAnswer
