import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { object } from 'yup'
import { Formik, Form } from 'formik'

import FormCTA from '../form/FormCTA'
import Button from '../common/Button'
import ResponseCard from '../common/ResponseCard'
import { PageHeader } from 'spd-mimo/components/Page'

import Constants from './_constants'
import { useReducerThunk, createSchema } from './_utils'
import Question from './Question'
import Answer from './Answer'
import Actions from './_actions'
import reducer, { initialState } from './_reducer'

import './styles.scss'

const DEFAULT_VALIDATION = {
  type: 'string',
  validations: [
    {
      type: 'required',
      params: ['An answer is required for this question.'],
    },
  ],
}

const Survey = ({
  children,
  onSubmitHandler,
  url,
  id,
  classNames,
  miscData,
  isMobile,
}) => {
  const useSurvey = useReducerThunk(reducer, initialState)
  const [surveyState, dispatcher] = useSurvey
  const [surveyFields, setSurveyFields] = useState({})
  const [surveySchema, setSurveySchema] = useState({})
  const _Survey = classnames('cui-survey', classNames)
  const ValidationSchema = object().shape(surveySchema)

  const _lmButton = classnames({ 'is-mobile': isMobile })

  const handleSubmit = values => {
    if (onSubmitHandler) {
      onSubmitHandler(values)
    } else {
      const apiUrl = url || Constants.API_URL
      dispatcher(Actions.SUBMIT({ ...miscData, ...values }, apiUrl))
    }
  }

  useEffect(() => {
    const processSurveyFields = () => {
      const _surveyFields = {}
      const _surveyFieldList = []
      React.Children.forEach(children, child => {
        if (child.type === Question) {
          if (child.props.id) {
            _surveyFields[child.props.id] = ''
            _surveyFieldList.push({
              id: child.props.id,
              validationOptions: child.props.optional
                ? { type: 'string', validations: [] }
                : DEFAULT_VALIDATION,
            })
          }
        }
      })
      const _schema = _surveyFieldList.reduce(createSchema, {})
      setSurveySchema(_schema)
      setSurveyFields(_surveyFields)
    }
    processSurveyFields()
  }, [children])

  return (
    <>
      {!surveyState.submit_success && (
        <>
          <PageHeader
            title="Thank you for using the SP Grid Connection Portal"
            description="We would like to hear about your experience"
          />
        </>
      )}
      <section className={_Survey} id={id}>
        {!surveyState.submit_success && (
          <Formik
            enableReinitialize
            validateOnMount
            isInitialValid={false}
            validationSchema={ValidationSchema}
            initialValues={surveyFields}
            onSubmit={handleSubmit}
          >
            <Form>
              {children}
              {!surveyState.submitting && surveyState.submit_fail && (
                <p>
                  There's a problem occured. Please try again submitting your
                  survey answers.
                </p>
              )}
              <FormCTA isCenter={false}>
                <Button type="submit" className={_lmButton}>
                  Submit
                </Button>
              </FormCTA>
            </Form>
          </Formik>
        )}

        {!surveyState.submitting && surveyState.submit_success && (
          <ResponseCard type="success" title="Thank you for your feedback">
            <Button link to={'/supply-connection'}>
              Back to main page
            </Button>
          </ResponseCard>
        )}
      </section>
    </>
  )
}

Survey.displayName = 'CuiSurvey'

Survey.Question = Question
Survey.Answer = Answer

Survey.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  onSubmitHandler: PropTypes.func,
}

Survey.defaultProps = {
  id: '',
  url: null,
  onSubmitHandler: null,
}

export default Survey
