import React from 'react'
import { Link } from 'react-router-dom'

import classnames from 'classnames'
import { PLATFORM } from 'spd-mimo/constants'

export const PageHeader = ({ title, description, children }) => {
  return (
    <div className="app-page--header">
      {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
      {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
      {children}
    </div>
  )
}

const Page = ({ children, platform }) => {
  const _AppMain = classnames('app-main app-page', {
    'is-mobile': PLATFORM.mobile === platform,
  })
  return (
    <>
      <main className={_AppMain}>{children}</main>
      {PLATFORM.web === platform && (
        <footer className="app-footer">
          <div className="app-footer__links">
            <a href="mailto:homeconnection@spgroup.com.sg">Contact Us</a>
            <Link to={`/supply-connection/feedback`}>Feedback</Link>
            <a
              href="https://www.spgroup.com.sg/wcm/connect/spgrp/e045da24-5af7-4022-9c35-38c309af627f/%5BInfo%5D+FAQs+on+Electricity+Supply+Connection+for+Landed+Residential+Premises.pdf?MOD=AJPERES"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </a>
          </div>
          <p className="app-footer__copyright">
            © {new Date().getFullYear()} Singapore Power Ltd. All Rights
            Reserved
          </p>
        </footer>
      )}
    </>
  )
}

Page.defaultProps = {
  isFeedback: false,
  platform: PLATFORM.web,
}

export default Page
