import React, { useState, useEffect, useRef } from 'react'
import OtpInput from 'react-otp-input'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import FormErrorBlock from '../ErrorBlock'
import Button from 'spd-mimo/components/common/Button'

const FormOtpInput = ({
  numberOfInputs,
  isInline,
  note,
  label,
  preOtp,
  endingRecepient,
  resendHandler,
  timeout,
  timeToResend,
  resent,
  autoFocus,
  children,
  ...props
}) => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange: formikHandleChange,
  } = useFormikContext()
  const hasError = touched[props.name] && errors[props.name]

  const [timeRemaining, setTimeRemaining] = useState(timeout)

  const _OtpInput = classnames('lm--formItem form-item field-otp', {
    'lm--formItem--inline': isInline,
    'is-error': hasError,
    'with-note': note,
  })

  let otpTimer = useRef(null)

  const handleChange = val => {
    if (setFieldValue) {
      setFieldValue(props.id, val)
      return
    }
    formikHandleChange(val)
  }

  const runOtpTime = () => {
    otpTimer.current = setInterval(() => {
      setTimeRemaining(prevState => prevState - 1)
    }, 1000)
  }

  const handleResentOpt = () => {
    resendHandler()
    setTimeRemaining(timeout)
  }

  useEffect(() => {
    const resetTimeRemaining = () => {
      setTimeRemaining(timeout)
    }
    const clearOtpTimer = () => {
      clearInterval(otpTimer.current)
      otpTimer.current = null
    }

    if (endingRecepient) {
      runOtpTime()
    }

    if (timeRemaining === 0) {
      clearOtpTimer()
      if (resent) {
        resetTimeRemaining()
      }
    }

    return () => {
      clearOtpTimer()
    }
  }, [endingRecepient, timeRemaining, resent, timeout])

  return (
    <div id={`${props.id}-form-item`} className={_OtpInput}>
      {label && label !== '' && (
        <div className="lm--formItem-label label">{label}</div>
      )}
      <div className="lm--formItem-control control">
        <div className="control-container">
          {preOtp && <span>{`${preOtp} -`}</span>}
          <OtpInput
            numInputs={numberOfInputs}
            inputStyle="field-otp__input"
            containerStyle="field-otp__cont"
            onChange={handleChange}
            shouldAutoFocus={autoFocus}
            value={values[props.id]}
            isDisabled={timeRemaining === 0 || props.disabled}
            {...props}
          />
        </div>
        {endingRecepient && (
          <div className="field-content">
            {timeRemaining > 0 && (
              <>
                <span>
                  Please enter the 5 numeric One-time Password (OTP) sent to
                  your mobile number <b>({` ${endingRecepient}`})</b> within{' '}
                  <b>{timeRemaining}</b> seconds.
                  <br />
                </span>
                <span>
                  {' '}
                  Not your mobile number? Please contact us at{' '}
                  <a href="mailto:homeconnection@spgroup.com.sg">
                    homeconnection@spgroup.com.sg
                  </a>{' '}
                  if the number is incorrect.
                </span>
              </>
            )}
            {timeRemaining === 0 && (
              <span className="expired-otp">
                Your OTP has expired.{' '}
                <Button link onClickHandler={handleResentOpt}>
                  Resend OTP
                </Button>{' '}
              </span>
            )}
            {timeRemaining <= timeout - timeToResend && timeRemaining > 0 && (
              <span className="resend-otp">
                Did not receive an OTP within 30 seconds?{' '}
                <Button link onClickHandler={handleResentOpt}>
                  Resend OTP
                </Button>
              </span>
            )}
            {children}
          </div>
        )}
        {hasError && <FormErrorBlock name={props.name} />}
        {note && <span className="field-note">{note}</span>}
      </div>
    </div>
  )
}

FormOtpInput.defaultProps = {
  isInline: true,
  numberOfInputs: 5,
  id: '',
  autoFocus: false,
  timeToResend: 30, // Secs
  timeout: 120, // Secs,
  endingRecepient: null,
}

export default FormOtpInput
