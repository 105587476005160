/**
 * Enum for applicationType
 * @readonly
 * @enum {string}
 */
import config from './config'

export const applicationType = {
  open: 'Open Utilities',
  close: 'Close Utilities',
}

export const homeConnectionMode = {
  'check-status': ['Check Status of My', 'Home Connection'],
  'make-payment': ['Make', 'Payment'],
}

export const residentialPremiseTypes = {
  landed: ['Landed', 'Residential Premises'],
  'non-landed': ['All Other', 'Premises'],
}

export const sapState = {
  '01': 'received',
  '02': 'progress',
  '03': 'processed',
  '04': 'completed',
}

export const status = {
  received: 'Received',
  progress: 'In Progress',
  processed: 'Processed',
  completed: 'Completed',
}

export const contact = {
  number: '1800 222 2333',
  tel: 'tel:18002222333',
}

export const axiosError = {
  'Network Error': `Connection error. Please check your internet connection. Otherwise, please contact us at ${contact.number}.`,
  [`timeout of ${config.api.conn.timeout}ms exceeded`]: 'Oops! We are not receiving a response from server. Please try again.',
}

export const error = {
  internalError: `Internal server error. Please contact us at ${contact.number}.`,
  invalid_app_number:
    "You have entered an invalid number. Please enter the application number for a landed residential property.<br/><br/>For more enquiries, please contact us via our 'Contact us' form available on our website.",
  invalid_app_number_payment:
    "You have entered an invalid number. Please enter a valid application number with an outstanding  quotation.<br/><br/>For more enquiries, please contact us via our 'Contact us' form available on our website.",
  invalid_mobile_number:
    "We are unable to retrieve your application. Please contact us via our 'Contact us' form available on our website.",
}

export const PLATFORM = {
  key: 'platform',
  web: 'web',
  kiosk: 'kiosk',
  mobile: 'mobile',
}
