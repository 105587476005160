import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as y from 'yup'
import config from '../config'
import ReCAPTCHA from 'react-google-recaptcha'
import InputField from './form/InputField'

export default function Reference(props) {
  const recaptchaRef = React.createRef()
  function onSubmit(values) {
    const captcha = recaptchaRef.current?.getValue()
    props.handleSubmit({
      ...values,
      captcha,
    })
  }

  const formSpec = getSpec(props.applicationType)

  return (
    <div className="reference">
      <div className="header">
        <p className="notice">{formSpec.label.header}</p>
      </div>
      <Formik
        initialValues={
          props.reference ? props.reference : formSpec.schema.default()
        }
        validationSchema={formSpec.schema}
        onSubmit={onSubmit}
      >
        <Form>
          <section className="form-group">
            {formSpec.fields.map(function(spec) {
              return <InputField key={spec.id} spec={spec} />
            })}
          </section>
          <div id="g-recaptcha" className="notice">
            <ReCAPTCHA
              sitekey={config.vendor.google.recaptcha}
              ref={recaptchaRef}
            />
          </div>

          <div className="lm--formItem lm--formItem--inline form-cta">
            <button
              onClick={props.backHome}
              className="btn--mimo lm--button lm--button--secondary"
            >
              Back
            </button>
            <button
              type="submit"
              className="btn--mimo lm--button lm--button--primary"
            >
              Submit
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}
Reference.propTypes = {
  reference: PropTypes.shape({
    id: PropTypes.string,
    postalCode: PropTypes.string,
    nric: PropTypes.string,
  }),
  applicationType: PropTypes.string,
  handleSubmit: PropTypes.func,
  backHome: PropTypes.func,
}

/**
 * @typedef {{id: string, type: string, label: string, placeholder: string}} InputFieldSpec
 * @typedef {{header: string, schema: Object, fields: Array<InputFieldSpec>}} FormSpec
 */
/**
 * Get Form configuration
 * @param {string} applicationType
 * @returns {FormSpec}
 */
export function getSpec(applicationType) {
  const spec = {
    open: {
      label: {
        header:
          'Please enter the following information to check your application status',
      },
      schema: y.object().shape({
        id: y
          .string()
          .label('Application ID')
          .default('')
          .required()
          .matches(
            /^[0-9]*$/g,
            'Please enter a valid 6 digit Application ID, must be numeric.'
          ),
        postalCode: y
          .string()
          .label('Postal Code')
          .default('')
          .required()
          .length(6)
          .matches(
            /^[0-9]*$/g,
            'Please enter a valid Postal Code, must be numeric.'
          ),
        nric: y
          .string()
          .label('NRIC/FIN')
          .default('')
          .required()
          .length(4)
          .matches(
            /^\d{3}[a-z]$/gim,
            'Please input the last 4 characters of your NRIC/FIN'
          ),
      }),
      fields: [
        {
          id: 'id',
          type: 'text',
          label: 'Application ID',
          placeholder: 'Application ID',
        },
        {
          id: 'postalCode',
          type: 'text',
          label: 'Postal Code',
          placeholder: 'Postal Code',
        },
        {
          id: 'nric',
          type: 'text',
          label: 'NRIC/FIN (Last 4 characters)',
          placeholder: 'Last 4 of NRIC/FIN',
        },
      ],
    },
    close: {
      label: {
        header:
          'Please enter the following information to check your application status',
      },
      schema: y.object().shape({
        id: y
          .string()
          .label('Account Number')
          .default('')
          .required()
          .length(10)
          .matches(
            /^[1-9]\d{8}(-|\d{1})$/gi,
            'Please enter a valid Account Number (eg. 9876543211 or 123456789-)'
          ),
        postalCode: y
          .string()
          .label('Postal Code')
          .default('')
          .required()
          .length(6)
          .matches(
            /^[0-9]*$/g,
            'Please enter a valid Postal Code, must be numeric.'
          ),
        nric: y
          .string()
          .label('NRIC/FIN/UEN')
          .default('')
          .required(),
        // captcha: y.string().required(),
      }),
      fields: [
        {
          id: 'id',
          type: 'text',
          label: 'Account Number',
          placeholder: 'Account Number',
        },
        {
          id: 'postalCode',
          type: 'text',
          label: 'Postal Code',
          placeholder: 'Postal Code',
        },
        {
          id: 'nric',
          type: 'text',
          label: 'NRIC/FIN (Last 4 characters) or UEN',
          placeholder: 'Last 4 of NRIC or UEN',
        },
      ],
    },
  }
  if (!spec.hasOwnProperty(applicationType)) {
    throw new TypeError(
      `Application Type '${applicationType}' is not defined in form spec`
    )
  }
  return spec[applicationType]
}
