import { flip, invoker, join, map, nAry, pipe, splitAt } from 'ramda'
import moment from 'moment'
import { PLATFORM } from 'spd-mimo/constants'

/**
 * Capitalize first letter
 * @param {string} str
 * @returns {string}
 */
const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * parse date
 * @type {function(string): string}
 * @function
 * @param {string} date
 * @returns {string} dateOut
 */
const parseDate = pipe(
  flip(nAry(2, moment))('DDMMYYYY'),
  invoker(1, 'format')('DD MMM YYYY')
)

/**
 * Formatting a list of string
 * @param {Array<string>} arr
 * @returns {string}
 */
function printArray(arr) {
  return pipe(
    map(capitalize),
    splitAt(arr.length - 1),
    map(join(', ')),
    join(' and ')
  )(arr)
}

const clearSession = () => {
  const values = ['platform', 'redirectUrl', 'paymentUrl'].map(key => ({
    key,
    value: window.sessionStorage.getItem(key),
  }))
  window.sessionStorage.clear()
  values.forEach(function({ key, value }) {
    if (value) {
      window.sessionStorage.setItem(key, value)
    }
  })
}

const getLandingUrl = platform => {
  switch (platform) {
    case PLATFORM.kiosk:
      return sessionStorage.getItem('redirectUrl')
    default:
      return `/supply-connection?platform=${platform}`
  }
}

const help = {
  capitalize,
  parseDate,
  printArray,
  clearSession,
  getLandingUrl,
}
export default help
