import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ProgressItem from './ProgressItem'
import Tabs from 'spd-mimo/components/Tabs'

import './styles.scss'

const Progress = ({ children, items, id, direction, progress }) => {
  const _Progress = classnames('cui-progress', {
    'is-crosswise': direction === 'row',
    'is-progress': progress,
  })

  const tabsState = useContext(Tabs.Context) || {}

  function renderChildren() {
    if (items.length > 0) {
      return (
        <>
          <div className="cui-progress__items">
            {items.map((item, itemIdx) => {
              const { content, active, ...itemProps } = item
              return (
                <ProgressItem
                  key={`${id}-progressitem--${itemIdx}`}
                  active={!!active}
                  isLastItem={itemIdx === tabsState.items.length - 1}
                  {...itemProps}
                >
                  {content}
                </ProgressItem>
              )
            })}
          </div>
          {children}
        </>
      )
    }
    return children
  }

  return <div className={_Progress}>{renderChildren()}</div>
}

Progress.displayName = 'CuiProgress'

Progress.propTypes = {
  items: PropTypes.array,
  direction: PropTypes.string,
  id: PropTypes.string,
  progress: PropTypes.bool,
}

Progress.defaultProps = {
  items: [],
  direction: 'row',
  id: '',
  progress: true,
}

Progress.Item = ProgressItem

export default Progress
