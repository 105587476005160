import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import moment from 'moment'
import Page, { PageHeader } from 'spd-mimo/components/Page'
import Detail from 'spd-mimo/components/common/Detail'
import Button from 'spd-mimo/components/common/Button'
import Loader from 'spd-mimo/components/common/Loader'
import ConfirmationDialog from 'spd-mimo/components/common/ConfirmationDialog'
import Icons from 'spd-mimo/components/common/Icons'
import Tabs from 'spd-mimo/components/Tabs'
import Progress from 'spd-mimo/components/Progress'
import help from '../../helpers'
import { PLATFORM } from 'spd-mimo/constants'
import ConnServActions from 'spd-mimo/redux/modules/connection-service/actions'
import Utils from 'spd-mimo/components/common/utils'

require('moment-timezone')

const milestones = {
  Application: {
    label: 'Application',
  },
  Quotation: {
    label: 'Quotation',
  },
  'Project Implementation': {
    label: 'Project Implementation',
  },
  'Testing/Completion': {
    label: 'Testing/Completion',
  },
}

const Status = ({ connectionService, platform, isMobile, dispatch }) => {
  const history = useHistory()
  const [makePaymentClicked, setMakePaymentClicked] = useState(false)

  const handleBack = useCallback(() => {
    const url = help.getLandingUrl(platform)
    if (PLATFORM.kiosk === platform) {
      window.location = url
    } else {
      history.push(url)
    }
  }, [history, platform])

  const checkExpiredQuotation = useCallback(() => {
    const {
      data: { status },
      get_status_success,
    } = connectionService

    if (!status?.quotation?.expiryDate) {
      return false
    }

    const expiryDate = moment(status.quotation.expiryDate).format('YYYY-MM-DD')

    const currentDate = moment()
      .tz('Asia/Singapore')
      .format('YYYY-MM-DD')

    return get_status_success && expiryDate < currentDate
  }, [connectionService])

  const handlePayment = useCallback(() => {
    setMakePaymentClicked(true)
    if (!checkExpiredQuotation()) {
      history.push('/supply-connection/payment')
    }
  }, [checkExpiredQuotation, history])

  useEffect(() => {
    const getStatus = () => {
      const {
        verify_otp_success,
        get_status_success,
        data,
        mode,
      } = connectionService
      const { application_id } = data
      if (
        (mode === 'check-status' && !verify_otp_success) ||
        !application_id ||
        !mode
      ) {
        handleBack()
      } else if (!get_status_success) {
        if (mode === 'check-status') {
          dispatch(ConnServActions.GET_STATUS({ application_id }))
        } else {
          dispatch(ConnServActions.GET_STATUS_WITHOUT_OTP({ application_id }))
        }
      }
    }
    getStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleErrContinue() {
    dispatch(ConnServActions.ERROR_CONTINUE())
    handleBack()
  }

  const statusContent = () => {
    const _btnContainer = classnames('flex row align-end', {
      'btn-container is-mobile': isMobile,
    })

    const status = connectionService.data.status
    if (status) {
      return (
        <div className="status-details">
          {status.paymentStatus === 'PROCESSING' ? (
            <>
              <h4 className="status-details__title with-subtitle">
                Payment made on {status.paymentDate}
              </h4>
              <p>
                Project implementation will commence after payment is received.
              </p>
            </>
          ) : (
            <h4 className="status-details__title">{status.message}</h4>
          )}
          <section style={isMobile ? { paddingBottom: '10px' } : {}}>
            <Detail.Node>
              {status.quotation && status.quotation.amount && (
                <>
                  <Detail.NodeItem>
                    <Detail.Label> Quotation Number </Detail.Label>
                    <Detail.Data>{status.quotation.number}</Detail.Data>
                  </Detail.NodeItem>
                  {status.status === 'QSNT' && (
                    <Detail.NodeItem>
                      <Detail.Label> Quotation Date </Detail.Label>
                      <Detail.Data>
                        {Utils.formatDate(
                          status.quotation.sentDate,
                          'DD MMM YYYY'
                        )}
                      </Detail.Data>
                    </Detail.NodeItem>
                  )}
                  <Detail.NodeItem>
                    <Detail.Label> Amount </Detail.Label>
                    <Detail.Data>
                      {`${Utils.priceFormat(status.quotation.amount)} w/GST`}
                    </Detail.Data>
                  </Detail.NodeItem>
                  {status.status === 'QSNT' && (
                    <Detail.NodeItem>
                      <Detail.Label> Amount Payable </Detail.Label>
                      <Detail.Data>
                        <b>
                          {Utils.priceFormat(status.quotation.payable)} w/GST
                        </b>
                      </Detail.Data>
                    </Detail.NodeItem>
                  )}
                </>
              )}
              {status.quotation &&
                status.quotation.paidDate &&
                status.status !== 'QSNT' && (
                  <>
                    <Detail.NodeItem>
                      <Detail.Label>Payment Received</Detail.Label>
                      <Detail.Data>
                        {Utils.formatDate(
                          status.quotation.paidDate,
                          'DD MMM YYYY'
                        )}
                      </Detail.Data>
                    </Detail.NodeItem>
                  </>
                )}
            </Detail.Node>
          </section>
          <section className="status-details__officer">
            {status.status !== 'QSNT' && status.projectOfficer && (
              <Detail.Node>
                <Detail.NodeItem>
                  <Detail.Label>SP Project Officer</Detail.Label>
                  <Detail.Data>{status.projectOfficer.name}</Detail.Data>
                </Detail.NodeItem>
                <Detail.NodeItem>
                  <Detail.Label>Contact Number</Detail.Label>
                  <Detail.Data>{status.projectOfficer.mobileNo}</Detail.Data>
                </Detail.NodeItem>
                <Detail.NodeItem>
                  <Detail.Label>Email</Detail.Label>
                  <Detail.Data>{status.projectOfficer.email}</Detail.Data>
                </Detail.NodeItem>
              </Detail.Node>
            )}
          </section>
          {status.paymentStatus !== 'PROCESSING' &&
            status.quotation &&
            status.status === 'QSNT' &&
            status.quotation.payable > 0 && (
              <section className={_btnContainer} style={{ marginTop: '3rem' }}>
                <Button
                  variant="primary"
                  onClickHandler={handlePayment}
                  className={isMobile ? 'is-mobile' : ''}
                >
                  Make payment
                </Button>
              </section>
            )}
        </div>
      )
    }
  }

  function renderTabNav() {
    const status = connectionService.data?.status
    return (
      <Tabs.Nav component={Progress} type="progress">
        {Object.keys(milestones).map(milestone => {
          return (
            <Tabs.Item
              key={`tabNav-item-${milestone}`}
              id={`statusNav-item-${milestone}`}
              active={status.milestone === milestone}
              ticked={
                milestone === 'Testing/Completion' && status.status === 'PASS'
              }
            >
              {milestones[milestone].label}
            </Tabs.Item>
          )
        })}
      </Tabs.Nav>
    )
  }

  function renderTabContent() {
    const status = connectionService.data?.status
    return (
      <Tabs.Content>
        {Object.keys(milestones).map(milestone => {
          const title = milestones[milestone].label
          const active = status.milestone === milestone
          const className = active ? 'cui-paper' : ''
          return (
            <Tabs.Item
              key={`tabContent-item-${milestone}`}
              id={`statusContent-item-${milestone}`}
              title={title}
              className={className}
              active={active}
            >
              {active && statusContent()}
            </Tabs.Item>
          )
        })}
      </Tabs.Content>
    )
  }

  return (
    <Page platform={platform}>
      {!isMobile && <PageHeader title="SP Grid Connection Portal" />}

      {connectionService.data.status ? (
        <>
          <section>
            <Detail.Node label="Application Information">
              <Detail.NodeItem>
                <Detail.Label> Application Number </Detail.Label>
                <Detail.Data>
                  {connectionService.data.application_id}
                </Detail.Data>
              </Detail.NodeItem>
              {connectionService.mode === 'check-status' && (
                <>
                  <Detail.NodeItem>
                    <Detail.Label> Premises Address </Detail.Label>
                    <Detail.Data>
                      {connectionService.data.status.premiseAddress}
                    </Detail.Data>
                  </Detail.NodeItem>
                  <Detail.NodeItem>
                    <Detail.Label> Licensed Electrical Worker </Detail.Label>
                    <Detail.Data>
                      {connectionService.data.status.lewName}
                    </Detail.Data>
                  </Detail.NodeItem>
                  <Detail.NodeItem>
                    <Detail.Label> Contact Number </Detail.Label>
                    <Detail.Data>
                      {connectionService.data.status.lewMobile}
                    </Detail.Data>
                  </Detail.NodeItem>
                </>
              )}
            </Detail.Node>
          </section>

          {connectionService.premiseType === 'landed' &&
          connectionService.mode === 'check-status' ? (
            <Tabs
              static
              id="status-milestone"
              direction="row"
              title="Process Milestone"
            >
              {renderTabNav()}
              {renderTabContent()}
            </Tabs>
          ) : (
            <div className="status-backdrop">{statusContent()}</div>
          )}
        </>
      ) : (
        !connectionService.error && <Loader> Fetching status... </Loader>
      )}

      <ConfirmationDialog
        visible={connectionService.get_status_fail && !!connectionService.error}
        confirmOkHandler={handleErrContinue}
        confirmCancelHandler={handleErrContinue}
        processing={false}
        actionConfig={{
          ok: {
            label: 'Back',
          },
          cancel: false,
        }}
        content={
          <>
            <figure className="alert-icon">
              <Icons.fail width={isMobile ? 180 : 240} />
            </figure>
            {isMobile ? (
              <>
                {connectionService.error &&
                connectionService.error.length > 100 ? (
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: connectionService.error,
                    }}
                  />
                ) : (
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: connectionService.error,
                    }}
                  />
                )}
              </>
            ) : (
              <h3
                dangerouslySetInnerHTML={{ __html: connectionService.error }}
              />
            )}
          </>
        }
      />

      <ConfirmationDialog
        className={`app-confirmation_dialog--${platform}`}
        visible={
          connectionService.get_status_success &&
          connectionService.data.status &&
          connectionService.mode === 'make-payment' &&
          (connectionService.data.status.milestone === 'Application' ||
            connectionService.data.status.status !== 'QSNT' ||
            connectionService.data.status.quotation.payable === 0)
        }
        confirmOkHandler={handleErrContinue}
        confirmCancelHandler={handleErrContinue}
        processing={false}
        actionConfig={{
          ok: {
            label: 'Back',
          },
          cancel: false,
        }}
        content={
          <h3>
            {connectionService.data.status &&
            connectionService.data.status.status === 'NOCN' &&
            connectionService.data.status.milestone === 'Application'
              ? 'Application is cancelled.'
              : 'There is no outstanding quotation that requires payment to be made for this application number.'}
          </h3>
        }
      />

      <ConfirmationDialog
        visible={
          checkExpiredQuotation() &&
          ((connectionService.mode === 'check-status' && makePaymentClicked) ||
            connectionService.mode === 'make-payment')
        }
        confirmOkHandler={handleErrContinue}
        confirmCancelHandler={handleErrContinue}
        processing={false}
        actionConfig={{
          ok: {
            label: 'Back',
          },
          cancel: false,
        }}
        content={
          <h3>
            We are unable to process the payment for this application. You may
            want to check the validity period of the quotation.{' '}
            {PLATFORM.kiosk === platform ? (
              "For more enquiries, please contact us via our 'Contact us' form available on our website."
            ) : (
              <>
                If you have queries, you may contact us at{' '}
                <a href="mailto:install@spgroup.com.sg">
                  install@spgroup.com.sg
                </a>
                .
              </>
            )}
          </h3>
        }
      />
    </Page>
  )
}

const mapStateToProps = state => ({
  connectionService: state.connectionService,
  platform: state.ctx.platform,
  isMobile: PLATFORM.mobile === state.ctx.platform,
})

export default connect(mapStateToProps)(Status)
