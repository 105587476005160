import React from 'react'
import { Field } from 'formik'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const CheckboxField = ({ spec, children }) => {
  const _CheckboxField = classnames(
    'lm--formItem form-item field-checkbox lm--formItem--inline'
  )
  return (
    <div className={_CheckboxField}>
      <div className="label lm--formItem-label">
        <label htmlFor={spec.id}>{spec.label}</label>
      </div>
      <div className="control lm--formItem-control">
        <Field
          aria-label={spec.label}
          data-testid={spec.id}
          id={spec.id}
          name={spec.id}
          type={spec.type}
          placeholder={spec.placeholder}
          {...spec}
        />
        <span className="checkmark"></span>
        {children && <div className="field-content">{children}</div>}
      </div>
    </div>
  )
}

CheckboxField.propTypes = {
  spec: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  }),
}

export default CheckboxField
