import help from '../../helpers'
import React from 'react'
import PropTypes from 'prop-types'
import { status } from '../../constants'

export const utilLabels = {
  utilities: {
    electricity: 'Electricity',
    water: 'Water',
    gas: 'Gas',
  },
  status: status,
}
const score = {
  received: 0,
  progress: 1,
  processed: 2,
  completed: 3,
}

export default function UtilCol({ data }) {
  const highestState = score[data.status] + 1
  return (
    <div className={`util-col col--${data.type}`}>
      <div className="col-header">{utilLabels.utilities[data.type]}</div>
      <div className="status-group">
        <ul className="lm--timeline vertical">
          {Object.keys(utilLabels.status)
            .map(function(key) {
              return (
                <li
                  key={`dot-${key}`}
                  className={`status ${
                    score[key] < highestState ? 'active blur' : 'inactive'
                  }`}
                />
              )
            })
            .fill(
              <li key={'dot-current'} className="status active blink" />,
              highestState - 1,
              highestState
            )}
        </ul>
      </div>
      <span className="">{help.parseDate(data.turnOnDate)}</span>
    </div>
  )
}
UtilCol.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    turnOnDate: PropTypes.string,
  }),
}
