const CONN_SERV_API_URL = process.env.REACT_APP_API_BASE || ''

export default {
  REQUEST_OTP: 'CONN_SERV_REQUEST_OTP',
  REQUEST_OTP_SUCCESS: 'CONN_SERV_REQUEST_OTP_SUCCESS',
  REQUEST_OTP_FAIL: 'CONN_SERV_REQUEST_OTP_FAIL',

  VERIFY_OTP: 'CONN_SERV_VERIFY_OTP',
  VERIFY_OTP_SUCCESS: 'CONN_SERV_VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAIL: 'CONN_SERV_VERIFY_OTP_FAIL',

  GET_STATUS: 'CONN_SERV_GET_STATUS',
  GET_STATUS_SUCCESS: 'CONN_SERV_GET_STATUS_SUCCESS',
  GET_STATUS_FAIL: 'CONN_SERV_GET_STATUS_FAIL',

  SUBMIT_PAYMENT: 'CONN_SERV_SUBMIT_PAYMENT',
  SUBMIT_PAYMENT_SUCCESS: 'CONN_SERV_SUBMIT_PAYMENT_SUCCESS',
  SUBMIT_PAYMENT_FAIL: 'CONN_SERV_SUBMIT_PAYMENT_FAIL',

  GET_PAYMENT_STATUS: 'CONN_SERV_GET_PAYMENT_STATUS',
  GET_PAYMENT_STATUS_SUCCESS: 'CONN_SERV_GET_PAYMENT_STATUS_SUCCESS',
  GET_PAYMENT_STATUS_FAIL: 'CONN_SERV_GET_PAYMENT_STATUS_FAIL',

  MAKE_PAYMENT: 'CONN_SERV_MAKE_PAYMENT',

  ERROR_CONTINUE: 'CONN_SERV_ERROR_CONTINUE',
  RESET: 'CONN_SERV_RESET',

  SERVER_ERROR: 'SERVER_ERROR',

  API_URL: CONN_SERV_API_URL,
}

export const khaosPaymentMode = {
  DEBIT_CARD: 'NETS_DEBIT',
  CREDIT_CARD: 'CREDIT_CARD',
}
