import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import FeedbackPage from './Feedback'
import Landing from './Landing'
import VerificationPage from './Verification'
import StatusPage from './Status'
import PaymentPage from './Payment'
import ConnServActions from 'spd-mimo/redux/modules/connection-service/actions'
import { identity } from 'ramda'

const ConnectionServiceIndex = ({ reset }) => {
  const history = useHistory()

  useEffect(() => {
    if (!history.location.pathname.includes('/supply-connection/payment')) {
      reset()
    }
  }, [history, reset])

  return (
    <>
      <Helmet>
        <title>SP Grid Connection Portal</title>
      </Helmet>
      <Switch>
        <Route exact path="/supply-connection" component={Landing} />
        <Route path="/supply-connection/feedback" component={FeedbackPage} />
        <Route
          path="/supply-connection/verification"
          component={VerificationPage}
        />
        <Route path="/supply-connection/status" component={StatusPage} />
        <Route path="/supply-connection/payment" component={PaymentPage} />
      </Switch>
    </>
  )
}

const mapDispatchToProps = { reset: ConnServActions.RESET }

export default connect(identity, mapDispatchToProps)(ConnectionServiceIndex)
