import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from 'spd-mimo/components/common/Modal'
import Icons from 'spd-mimo/components/common/Icons'

class Tooltip extends PureComponent {
  state = {
    show: false,
  }
  render() {
    const { label, withIcon, arrow, children, ...rest } = this.props
    const { show } = this.state
    return (
      <Fragment>
        <div className="tooltip" {...rest}>
          <button
            className="tooltip-target"
            ref={el => {
              this._target = el
            }}
            onClick={this._tooltipOpenHandler}
            type="button"
          >
            {withIcon && <Icons.info width={16} height={16} />}
            <span>{label}</span>
          </button>
        </div>
        <Modal
          target={this._target}
          visible={show}
          className={`tooltip_pop`}
          onClose={this._tooltipCloseHandler}
          onEscapeKeyDown={this._tooltipOnEscKeyDownHandler}
          withBackdrop={false}
          arrow={arrow}
        >
          {children}
        </Modal>
      </Fragment>
    )
  }
  _tooltipCloseHandler = () => {
    const { onPopClose } = this.props
    this.setState(
      prevState => ({
        ...prevState,
        show: false,
      }),
      () => {
        if (onPopClose) {
          onPopClose()
        }
      }
    )
  }

  _tooltipOpenHandler = () => {
    const { onPopOpen } = this.props
    this.setState(
      prevState => ({
        ...prevState,
        show: true,
      }),
      () => {
        if (onPopOpen) {
          onPopOpen()
        }
      }
    )
  }

  _tooltipOnEscKeyDownHandler = () => {
    this._tooltipCloseHandler()
  }
}

Tooltip.propTypes = {
  label: PropTypes.string,
  withIcon: PropTypes.bool,
}

Tooltip.defaultProps = {
  withIcon: true,
}

export default Tooltip
