import { PLATFORM } from 'spd-mimo/constants'

// Actions
export const CHECK_MAINTENANCE = 'ctx/CHECK_MAINTENANCE'
export const INIT_PLATFORM = 'ctx/INIT_PLATFORM'

// Reducers
const initState = {
  platform: sessionStorage.getItem(PLATFORM.key),
  maintenance: {},
}
export default function reducer(state = initState, action) {
  switch (action.type) {
    case CHECK_MAINTENANCE:
      return {
        ...state,
        maintenance: { ...action.data },
      }
    case INIT_PLATFORM:
      return {
        ...state,
        platform: action.data,
      }
    default:
      return { ...state }
  }
}

// Action creators
export function initPlatform(search) {
  return function(dispatch) {
    const queries = new URLSearchParams(search)
    if (!sessionStorage.getItem(PLATFORM.key) || queries.get(PLATFORM.key)) {
      const platform = queries.get(PLATFORM.key) || PLATFORM.web
      sessionStorage.setItem(PLATFORM.key, platform)
      if (PLATFORM.kiosk === platform && queries.get('redirectUrl')) {
        ;['redirectUrl', 'paymentUrl'].forEach(key =>
          sessionStorage.setItem(key, atob(queries.get(key)))
        )
        // disable overscroll behaviour for kiosk mode
        const _html = document.querySelector('html')
        const _body = document.querySelector('body')
        if (_html && _body) {
          _html.style.overscrollBehavior = 'none'
          _body.style.overscrollBehavior = 'none'
        }
      }
      dispatch({ type: INIT_PLATFORM, data: platform })
    }
  }
}

export const checkMaintenance = () => {
  return async (dispatch, _, { api }) => {
    try {
      const res = await api.conn.getMaintenance()
      return dispatch({ type: CHECK_MAINTENANCE, data: res.data })
    } catch (error) {
      const defaultResponse = {
        'conn-serv': {
          'active': true,
          'message': 'Thank you for visiting our website. We are sorry that this e-service is currently not available. We will resume services as soon as possible. Thank you for your understanding and patience.'
        },
        'mimo': {
          'active': true,
          'message': 'Thank you for visiting our website. We are sorry that this e-service is currently not available. We will resume services as soon as possible. Thank you for your understanding and patience.'
        }
    }
      dispatch({ type: CHECK_MAINTENANCE, data: defaultResponse })
    }
  }
}

