import React, { useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import Page, { PageHeader } from 'spd-mimo/components/Page'
import { useHistory } from 'react-router-dom'
import Icons from 'spd-mimo/components/common/Icons'
import {
  homeConnectionMode,
  PLATFORM,
  residentialPremiseTypes,
} from 'spd-mimo/constants'
import help from '../../helpers'
import ConnServActions from 'spd-mimo/redux/modules/connection-service/actions'
import CustomersGuide from './landing/CustomersGuide'

const Landing = ({ platform, isMobile, dispatch }) => {
  const [selectedMode, setSelectedMode] = useState(null)
  const [premiseType, setPremiseType] = useState(null)
  const [isSelectedPremiseType, setIsSelectedPremiseType] = useState(false)

  const history = useHistory()

  const _landingBtnCheck = classnames('landing-btncheck', {
    'btn-container is-mobile': isMobile,
  })

  const _lmButton = className => {
    return classnames('btn--mimo lm--button', className, {
      'is-mobile': isMobile,
    })
  }

  const _landingInfo = classnames('landing-info', {
    'is-mobile': isMobile,
  })

  const handleCheckStatus = () => {
    if (selectedMode) {
      history.push(
        `/supply-connection/verification?mode=${selectedMode}&premiseType=${premiseType}`
      )
    }
  }

  const handleProceedPremiseType = () => {
    if (premiseType === 'non-landed') {
      setSelectedMode('make-payment')
    } else {
      setSelectedMode(null)
    }
    setIsSelectedPremiseType(selected => !selected)
  }

  const checkMobilePlatform = () => {
    if (isMobile) {
      if (document.body) {
        document.body.classList.add('scp-mobile')
      }
    }
  }

  React.useEffect(() => {
    checkMobilePlatform()
    help.clearSession()
    dispatch(ConnServActions.RESET())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page platform={platform}>
      {!isMobile && <PageHeader title="SP Grid Connection Portal" />}
      <p className="landing-subtitle">
        {isSelectedPremiseType
          ? 'I want to'
          : 'Tell us what is your premises type'}
      </p>
      <div className="landing-container">
        <div className="choose-type">
          {!isSelectedPremiseType &&
            Object.entries(residentialPremiseTypes).map(function([key, label]) {
              return (
                <button
                  key={`choose-${key}`}
                  className={`choice ${
                    premiseType === key ? 'selected' : 'false'
                  }`}
                  data-testid={`residential-checkbox-${key}`}
                  onClick={() => setPremiseType(key)}
                >
                  <figure>
                    {key === 'landed' ? (
                      <Icons.residential
                        width={68}
                        color={
                          premiseType === 'landed' ? ['#00b0b2'] : ['#D8D8D8']
                        }
                      />
                    ) : (
                      <Icons.commercial
                        width={68}
                        color={
                          premiseType === 'non-landed'
                            ? ['#00b0b2']
                            : ['#D8D8D8']
                        }
                      />
                    )}
                  </figure>
                  <span>
                    {label.map((l, i) => {
                      if (i === 0) {
                        return (
                          <div key={i}>
                            {l}
                            <br />
                          </div>
                        )
                      }
                      return l
                    })}
                  </span>
                </button>
              )
            })}
          {isSelectedPremiseType &&
            Object.entries(homeConnectionMode)
              .filter(([key]) => {
                return (
                  premiseType === 'landed' ||
                  (premiseType === 'non-landed' && key === 'make-payment')
                )
              })
              .map(function([key, label]) {
                return (
                  <button
                    key={`choose-${key}`}
                    className={`choice rm-after ${
                      selectedMode === key ? 'selected' : 'false'
                    }`}
                    data-testid={`electricity-checkbox-${key}`}
                    onClick={() => {
                      setSelectedMode(key)
                    }}
                  >
                    <figure>
                      {key === 'check-status' ? (
                        <Icons.electricity width={48} color={[]} />
                      ) : (
                        <Icons.cardPayment width={48} color={[]} />
                      )}
                    </figure>
                    <span>
                      {label.map((l, i) => {
                        if (i === 0) {
                          return (
                            <div key={i}>
                              {l}
                              <br />
                            </div>
                          )
                        }
                        return l
                      })}
                    </span>
                  </button>
                )
              })}
        </div>
        <div className={_landingInfo}>
          <ul>
            {premiseType === 'landed' && selectedMode === 'check-status' && (
              <li>
                <span>Applicable for Landed Residential Premises only.</span>
              </li>
            )}
            {!isSelectedPremiseType && (
              <li>
                <span>
                  This service allows you to make payment for your grid
                  quotation and/or check the latest status.
                </span>
              </li>
            )}
            <li>
              <span>
                {`A 10 numeric application number is required${
                  isSelectedPremiseType && selectedMode === 'make-payment'
                    ? ' to make payment for your electricity connection application'
                    : ''
                }.`}
              </span>
            </li>

            {selectedMode === 'make-payment' && (
              <li>
                <span>
                  Payment can be made via direct debit from your bank account or
                  by debit/credit card. Credit card payment is available only
                  for quotation amount up to $40,000.
                </span>
              </li>
            )}
            <li>
              <CustomersGuide />
            </li>
          </ul>
        </div>
        <div className={_landingBtnCheck}>
          {!isSelectedPremiseType ? (
            <button
              className={_lmButton('lm--button--primary')}
              onClick={handleProceedPremiseType}
              disabled={!premiseType}
              data-testid="proceed-premise"
            >
              Proceed
            </button>
          ) : (
            <>
              <button
                className={_lmButton('lm--button--secondary')}
                onClick={handleProceedPremiseType}
                style={{ marginRight: '1rem' }}
              >
                Cancel
              </button>
              <button
                className={_lmButton('lm--button--primary')}
                onClick={handleCheckStatus}
                disabled={!selectedMode}
                data-testid="check-status"
              >
                Start
              </button>
            </>
          )}
        </div>
      </div>
    </Page>
  )
}

const mapStateToProps = state => ({
  connectionService: state.connectionService,
  isMobile: PLATFORM.mobile === state.ctx.platform,
  platform: state.ctx.platform,
})

export default connect(mapStateToProps)(Landing)
