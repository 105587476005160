import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useScreenSize } from '../ScreenSize'

import Tabs from './Tabs'

const TabItem = ({
  children,
  component,
  handleClick,
  className,
  forNav,
  index,
  ...props
}) => {
  const screenSize = useScreenSize()

  const tabsState = useContext(Tabs.Context) || {}
  const _TabItem = classnames('cui-tabs__item', className, {
    'is-type-progress': tabsState.navType === 'progress',
    'is-active': tabsState.active === index,
  })

  function renderComponent() {
    const onClickHandler = forNav ? handleClick(index) : null
    const nonNavProps = {}
    const componentProps = { className: _TabItem, onClick: onClickHandler }

    const componentChildren = [children]
    if (!forNav) {
      let itemNav = null
      nonNavProps['data-tab-title'] = props.title
      nonNavProps['data-tab-index'] = index
      if (screenSize.width < screenSize.breakpoints.MOBILE.width) {
        itemNav = (
          <div
            key={`${props.title}---itemNav`}
            className="cui-tabs__itemNav for-mobile"
          >
            {props.title}
          </div>
        )
      }
      componentChildren.unshift(itemNav)
    }
    return React.createElement(
      component,
      { ...componentProps, ...nonNavProps },
      componentChildren
    )
  }

  return renderComponent()
}

TabItem.displayName = 'CuiTabItem'

TabItem.propTypes = {
  component: PropTypes.string,
  onClickHandler: PropTypes.func,
  className: PropTypes.string,
  index: PropTypes.number,
  forNav: PropTypes.bool,
  title: PropTypes.string,
}

TabItem.defaultProps = {
  component: 'div',
  forNav: false,
  title: '',
}

export default TabItem
