import React from 'react'
import { Field } from 'formik'

const FormSwitchRadio = ({ spec, checked, onChange, label, ...props }) => {
  return (
    <div className="field-switch-radio">
      <Field className="btn--mimo lm--switch-input" {...spec} type="radio" />
      {/* <input className='lm--switch-input'  type='radio' id={spec.id} name={spec.name} value={value} checked={checked} {...props} /> */}
      <label htmlFor={spec.id} className="lm--switch-label">
        {spec.label}
      </label>
    </div>
  )
}

export default FormSwitchRadio
