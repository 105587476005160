import React from 'react'

const DetailNode = ({ label, children, ...props }) => {
  return (
    <div className="cui-detail_section" {...props}>
      {label !== '' && (
        <div className="cui-detail_section__title">
          <div className="cui-label">
            <h4>{label}</h4>
          </div>
        </div>
      )}
      <div className="cui-detail_section__content">{children}</div>
    </div>
  )
}

const DetailNodeItem = ({ children }) => {
  return <div className="cui-detail_section__item">{children}</div>
}

const DetailNodeItemLabel = ({ children }) => {
  return <div className="cui-label">{children}</div>
}

const DetailNodeItemData = ({ children, render, data }) => {
  function renderChildren() {
    if (children) {
      if (typeof children === 'function') {
        return children(data)
      }
      return children
    } else if (render) {
      console.warn(
        'Please pass this render function in the children of this component instead.'
      )
    }
    return null
  }

  return <div className="cui-data">{renderChildren()}</div>
}

DetailNode.defaultProps = {
  label: '',
}

DetailNodeItem.defaultProps = {
  children: null,
}

DetailNodeItemLabel.defaultProps = {
  children: null,
}

DetailNodeItemData.defaultProps = {
  data: {},
}

export default {
  Node: DetailNode,
  NodeItem: DetailNodeItem,
  Label: DetailNodeItemLabel,
  Data: DetailNodeItemData,
}
