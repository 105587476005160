import React from 'react'
import Icons from './Icons'

const Loader = ({ children }) => {
  return (
    <div className="app-loader">
      <Icons.spinloader color={['#00b0b2']} />
      {children}
    </div>
  )
}

export default Loader
