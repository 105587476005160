import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProgressItem = ({ children, active, isLastItem, ticked }) => {
  const _ProgressItem = classnames('cui-progress__item', {
    'is-active': active && !isLastItem && !ticked,
  })
  return <div className={_ProgressItem}>{children}</div>
}

ProgressItem.displayName = 'CuiProgressItem'

ProgressItem.propTypes = {
  active: PropTypes.bool,
}

ProgressItem.defaultProps = {
  active: false,
}

export default ProgressItem
