import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Tabs from './Tabs'
import TabItem from './TabItem'

const isValidChildType = type => {
  return type === TabItem
}

const TabNav = ({ id, className, children, component, type }) => {
  const tabsContext = useContext(Tabs.Context) || {}

  const _TabNav = classnames('cui-tabs__nav', className, type)

  function shouldBeClickable() {
    if (tabsContext.actions) {
      if (tabsContext.actions.handleTabClick) return true
    }

    if (!tabsContext.static) {
      return true
    }

    return false
  }

  function renderChildren() {
    return React.Children.map(children, (child, idx) => {
      if (isValidChildType(child.type)) {
        const id = child.props.id || `tabs-nav_${tabsContext.id}-idx`
        const props = {
          ...child.props,
          id,
          index: idx,
          forNav: !!tabsContext.actions,
          handleClick: shouldBeClickable()
            ? tabsContext.actions.handleTabClick
            : null,
        }
        return React.cloneElement(child, props)
      }
    })
  }

  function renderComponent() {
    const componentProps = { id }
    if (typeof component === 'string') {
      return React.createElement(component, componentProps, renderChildren())
    }
    const CustComponent = component
    return (
      <CustComponent
        {...componentProps}
        items={tabsContext.items}
        direction={tabsContext.direction === 'row' ? 'column' : 'row'}
      />
    )
  }

  return (
    <div className={_TabNav}>
      {tabsContext.title !== '' && <h4>{tabsContext.title}</h4>}
      {renderComponent()}
    </div>
  )
}

TabNav.displayName = 'CuiTabNav'
TabNav.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, child => {
      if (!isValidChildType(child.type)) {
        error = new Error(
          `${componentName} only accepts children of type TabItem. Please use Tabs.Item component instead.`
        )
      }
    })
    return error
  },
  className: PropTypes.string,
  id: PropTypes.string,
}
TabNav.defaultProps = {
  component: 'div',
  type: '',
}

export default TabNav
