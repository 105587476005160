import React, { useCallback, useState } from 'react'
import Tabs from 'spd-mimo/components/Tabs'
import Modal from '../../../components/common/Modal'
const milestones = {
  Application: {
    label: 'Application',
    content: [
      {
        text: [
          'LEW submits online Supply Application (CS1) form on eBusiness portal.',
        ],
      },
      {
        text: ['SP sends out application acknowledgement letter.'],
      },
    ],
  },
  Quotation: {
    label: 'Quotation',
    content: [
      {
        text: [
          'Customer receives either an offer of quotation or required response letter from SP.',
        ],
        note: '2 weeks',
      },
      {
        text: ['Customer accepts quotation and makes payment.'],
      },
      {
        text: ['Customer receives payment acknowledgement letter.'],
      },
      {
        text: [
          'Customer opens a utilities account for the premises with the IRAS letter or Certificate of Numbering letter.',
        ],
      },
      {
        text: [
          'Customer receives an introductory letter with details of assigned Project Officer (PO).',
        ],
        note: '1 week',
      },
    ],
  },
  'Project Implementation': {
    label: 'Project Implementation',
    content: [
      {
        text: [
          'LEW seeks approval from Electricity Meters section for metering requirements<sup>#</sup>.',
        ],
      },
      {
        text: [
          ' LEW informs PO of the estimated date when site is ready for SP to take over<sup>#</sup> (Customer’s switchboard and cable entry pipe are ready for service connection).',
        ],
      },
      {
        text: [
          'SP takes over site and submits applications to relevant agencies, e.g. LTA, PUB, NParks to seek approval to commence supply connection work.',
        ],
      },
      {
        text: [
          'SP commences supply connection work after approvals are granted.',
        ],
      },
      {
        text: ['Cable installation is completed and service cable is "live".'],
        note: 'Within 6 weeks of SP taking over site',
      },
    ],
  },
  'Testing/Completion': {
    label: 'Testing and Completion',
    content: [
      {
        text: [
          'LEW makes appointment with SP for inspection and turn-on<sup>#</sup><br>(Appointment can be made 10 calendar days in advance).',
        ],
      },
      {
        text: [
          'SP carries out testing<br>(The IRAS letter/Certificate of Numbering letter used to open the utilities account must be produced during inspection).',
        ],
      },
      {
        text: [
          'Inspection is successful and supply is turned on<sup>#</sup><br>(LEW needs to make a new appointment if Inspection fails).',
        ],
      },
    ],
  },
}
export default function CustomersGuide() {
  const [isOpenPopup, setOpenPopup] = useState(false)
  const toggleFn = useCallback(() => setOpenPopup(state => !state), [
    setOpenPopup,
  ])
  return (
    <>
      <span>
        For the Customer’s Guide to Electricity Grid Connection, click
      </span>
      <button className="btn-more" onClick={toggleFn}>
        here
      </button>
      <Modal
        visible={isOpenPopup}
        onClose={toggleFn}
        className="landing-modal"
        data-testid="landing-modal"
      >
        <div className="landing-modal__wrapper">
          <div className="landing-modal__content">
            <h3 className="landing-modal__title">Process Milestones</h3>
            <div className="landing-modal__subtitle">
              Click on each process for more details
            </div>
            <Nav />
          </div>
          <div className="landing-modal__notes">
            <div className="title">Notes:</div>
            <ul>
              <li>
                # Time taken to complete each variable milestone may differ for
                every application
              </li>
            </ul>
          </div>
          <span
            className="landing-modal__close"
            onClick={toggleFn}
            data-testid="close-modal"
          />
        </div>
      </Modal>
    </>
  )
}

function Nav() {
  return (
    <Tabs>
      <Tabs.Nav type="non-progress">
        {Object.keys(milestones).map(milestone => {
          const milestoneItem = milestones[milestone]
          return (
            <Tabs.Item
              key={`tabNav-item-${milestone}`}
              id={`statusNav-item-${milestone}`}
              active={milestone === 'Application'}
            >
              <div className="title">
                {milestoneItem.label}
                <span className="arrow" />
              </div>
              <div className="content">
                <ol>
                  {milestoneItem.content.map((item, index) => {
                    return (
                      <li key={index} className="content-item">
                        {item.text.map((text, idx) => (
                          <span
                            key={idx}
                            dangerouslySetInnerHTML={{ __html: `${text}` }}
                          />
                        ))}
                        {item.note && (
                          <span
                            className="note"
                            dangerouslySetInnerHTML={{
                              __html: `${item.note}`,
                            }}
                          />
                        )}
                      </li>
                    )
                  })}
                </ol>
              </div>
            </Tabs.Item>
          )
        })}
      </Tabs.Nav>
    </Tabs>
  )
}
