/**
 * Web API Wrapper
 *
 * This API exists because it helps unit testing and
 * also for convenient usage by reducing boilerplate such as JSON encoding
 * @param {Window} _window
 * @returns {{storage: StoreFactory}} webApi
 * @constructor
 */
export default function WebApi(_window) {
  /**
   * StoreFactory
   * @param {Storage} storage
   * @param {String} key
   * @returns {{set: Function, get: Function, clear: Function}}
   * @constructor
   */
  function StoreFactory(storage, key) {
    function get() {
      try {
        const data = storage.getItem(key)
        return data ? JSON.parse(data) : undefined
      } catch (e) {
        _window.console.error(e)
      }
    }

    function set(data) {
      storage.setItem(key, JSON.stringify(data))
    }

    function clear() {
      storage.removeItem(key)
    }
    return { get, set, clear }
  }

  return {
    storage: {
      state: StoreFactory(_window.sessionStorage, 'state'),
    },
  }
}
