import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Field, useFormikContext } from 'formik'

import ErrorBlock from '../ErrorBlock'

const TextAreaComponent = ({ field, form, ...props }) => {
  const { handleTextareaChange, ...otherProps } = props
  return <textarea {...field} {...otherProps} onChange={handleTextareaChange} />
}

const FormTextarea = ({ spec: { label, ...otherSpec }, isInline }) => {
  const { errors, touched, handleChange, setFieldError } = useFormikContext()
  const hasError = touched[otherSpec.name] && errors[otherSpec.name]
  const _FormTextarea = classnames('lm--formItem field-textarea', {
    'is-inline': isInline,
    'has-error': hasError,
  })
  const [remaining, setRemaining] = useState(otherSpec.maxlength)

  const checkValueForMinMax = value => {
    if (otherSpec.maxlength > 0) {
      if (remaining > 0) {
        setRemaining(otherSpec.maxlength - value.length)
      } else {
        setRemaining(0)
      }
    }
    if (otherSpec.minlength > 0) {
      if (value.length < otherSpec.minlength) {
        setFieldError(
          otherSpec.name,
          `Textarea should have a minimum characters of ${otherSpec.minlength}`
        )
      }
    }
  }

  const handleTextareaChange = e => {
    const { value } = e.target
    checkValueForMinMax(value)
    handleChange(e)
  }

  return (
    <div className={_FormTextarea}>
      {label && (
        <div className="label">
          <label className="lm--formItem-label" htmlFor={otherSpec.id}>
            {label}
          </label>
        </div>
      )}
      <div className="control">
        {otherSpec.maxlength > 0 && (
          <span className="control-counter">{`[${remaining}]`}</span>
        )}
        <Field
          id={otherSpec.id}
          name={otherSpec.name}
          placeholder={otherSpec.placeholder}
          rows="10"
          maxLength={otherSpec.maxlength}
          minLength={otherSpec.minlength}
          handleTextareaChange={handleTextareaChange}
          component={TextAreaComponent}
        />
        {hasError && <ErrorBlock name={otherSpec.name} />}
      </div>
    </div>
  )
}

FormTextarea.defaultProps = {
  isInline: true,
  spec: {},
  children: null,
}

FormTextarea.propTypes = {
  isInline: PropTypes.bool,
  spec: PropTypes.object.isRequired,
  children: PropTypes.object,
}

export default FormTextarea
