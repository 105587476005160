import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

const FormReCAPTCHA = ({ sitekey, id, disabled, label }) => {
  const { setFieldValue } = useFormikContext()
  const _FormReCAPTCHA = classnames(
    'lm--formItem form-item field-recaptcha lm--formItem--inline',
    {
      'is-disabled': disabled,
    }
  )

  const handleOnChange = value => {
    setFieldValue(id, value)
  }

  return (
    <div className={_FormReCAPTCHA}>
      <div className="lm--formItem-label label">{label}</div>
      <div className="lm--formItem-control control">
        <ReCAPTCHA id={id} sitekey={sitekey} onChange={handleOnChange} />
      </div>
    </div>
  )
}

FormReCAPTCHA.propTypes = {
  sitekey: PropTypes.string.isRequired,
  label: PropTypes.string,
}
FormReCAPTCHA.defaultProps = {
  sitekey: '',
  label: '',
}

export default FormReCAPTCHA
