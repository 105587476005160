import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { object, string, boolean } from 'yup'
import { Formik, Form } from 'formik'
import Page, { PageHeader } from 'spd-mimo/components/Page'
import FormOTP from 'spd-mimo/components/form/FormOTP'
import FormInput from 'spd-mimo/components/form/InputField'
import FormCTA from 'spd-mimo/components/form/FormCTA'
import FormReCAPTCHA from 'spd-mimo/components/form/FormReCAPTCHA'
import FormCheckbox from 'spd-mimo/components/form/CheckboxField'
import Button from 'spd-mimo/components/common/Button'
import Icons from 'spd-mimo/components/common/Icons'
import Tooltip from 'spd-mimo/components/common/Tooltip'
import ConfirmationDialog from 'spd-mimo/components/common/ConfirmationDialog'
import Modal from 'spd-mimo/components/common/Modal'
import config from 'spd-mimo/config'
import { homeConnectionMode } from 'spd-mimo/constants'
import ConnServActions from 'spd-mimo/redux/modules/connection-service/actions'
import Utils from '../../components/common/utils'
import help from '../../helpers'
import { PLATFORM } from 'spd-mimo/constants'

const tncContent = [
  {
    title: 'COPYRIGHT AND TRADEMARK NOTICES',
    contents: [
      'Except as otherwise expressly stated herein, the copyright and all other intellectual property in the  contents  of  this  Website  (including,  but  not  limited  to,  all  design,  text,  sound  recordings, images or links) are the property of Singapore Power Limited and/or its subsidiaries and/or their respective  subsidiaries  (together,  the  "SP  Group").  As  such,  they  may  not  be  reproduced, transmitted, published, performed, broadcast, stored, adapted, distributed, displayed, licensed, altered, hyperlinked or otherwise used in whole or in part in any manner without the prior written consent  of  SP  Group.  Save  and  except  with  SP  Group\'s  prior  written  consent,  you  may  not insert a hyperlink to this Website or any part thereof on any other website or "mirror" or frame this Website, any part thereof, or any information or materials contained in this Website on any other server, website or webpage.',
      "All trade marks, service marks and logos used in this Website are the property of SP Group and/or  the  respective  third  party  proprietors  identified  in  this  Website.  No  licence  or  right  is granted  and  your  access  to  this  Website  and/or  use  of  the  online  services  should  not  be construed  as  granting,  by  implication,  estoppel  or  otherwise,  any  license  or  right  to  use  any trade marks, service marks or logos appearing on the Website without the prior written consent of  SP  Group  or  the  relevant  third  party  proprietor  thereof.  Save  and  except  with  SP Group's prior written consent, no such trade mark, service mark or logo may be used as a hyperlink or to mark any hyperlink to any SP Group member's site or any other site.",
    ],
  },
  {
    title: 'DISCLAIMER',
    contents: [
      {
        subtitle:
          'The contents of this Website are provided on an “as is” and “as available” basis without warranties of any kind, and are of a general nature which have not been verified, considered or assessed  by  any  member  of  SP  Group  in  relation  to  the  making  of  any  specific  investment, business, financial or commercial decision. SP Group does not warrant and hereby disclaims all warranties of any kind, implied, express, or statutory, including but not limited to warranties:',
        subContent: [
          '<span>(a)</span> as   to   the   truth,   accuracy,   adequacy,   completeness   of   reasonableness   of   the information and materials contained in or accessed through this Website and expressly disclaims liability for any errors in, or omissions from, such information and materials;',
          '<span>(b)</span> as  to  the  title,  merchantability,  satisfactory  quality,  non-infringement  of  third  party intellectual property rights, or fitness for any particular purpose of the information and materials on this Website, or this Website in general; and',
          '<span>(c)</span> that the contents available through this Website or any functions associated therewith will be uninterrupted or error-free, or that defects will be corrected or that this Website and the server is and will be free of all viruses and/or other harmful elements. SP Group shall not be liable regardless of the form of action for any failure of performance system, server or connection failure, error, omission, interruption, breach of security, computer virus, malicious code, corruption, delay in operation or transmission, transmission error or unavailability of access in connection with your accessing this Website and/or using the online services even if SP Group has been advised as to the possibility.',
        ],
      },
      'In no event shall SP Group be liable to you or any other party for any damages, losses, expenses or costs whatsoever(including without limitation, any direct, indirect, special, incidental or consequential damages, loss of profits or loss of opportunity) arising in connection with  your  use  of  this  Website,  or  reliance  on  any  information,  materials  or  online  services provided at this Website, regardless of the form of action, even if SP Group had been advised as to the possibility of such damages.',
    ],
  },
  {
    title: 'ACCESS TO OTHER WEBSITES / HYPERLINKS',
    contents: [
      'For  your  convenience,  this  Website  may  contain  hyperlinks  to  websites, channels and/or applications  which  are  owned, operated or maintained by third parties. Such linked websites, channels and/or applications, including their features, functionalities, services and/or products are not provided by and/or not under the control of SP Group. SP is not responsible for examining or evaluating, and does not warrant the offerings of any such third parties and the SP  Group  is  not  responsible  for  the  contents  of  those  websites, channels and/or applications.  Use of the hyperlinks and access to such websites, channels and/or applications are entirely at your own risk and the SP Group shall  not  be liable  for  any  damages  or  loss caused or alleged to be caused by, in connection with or arising from access to those websites, channels and/or applications, any contents, material or functions contained on, or any errors, omissions or inaccuracies found on, any such websites, channels and/or applications or the conduct of any such third parties. Any hyperlinks provided in this Website shall not be construed as an endorsement or verification of such linked websites, channels and/or applications or the contents therein by SP Group.',
      'Access and/or use of additional services of other third parties may be provided under separate agreements (in which event you will be subject to additional terms and conditions) between you and such other third parties where necessary. Such agreements shall strictly be between such other third parties and you and under no circumstances shall it be construed that SP Group is a party to any transaction between such other third parties and you or that SP Group endorses, sponsors, certifies or is involved in the provision of such additional services.',
      'In no circumstances shall SP Group be considered to be associated or affiliated with any trade or service marks, logos, insignia or other devices used or appearing on websites to which this Website is linked.',
    ],
  },
  {
    title: 'LOGIN NAME, PASSWORD AND ACCOUNT',
    contents: [
      'If you use this Website, you are responsible for maintaining the confidentiality of your User ID, password and Account and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your User ID, password and Account. If you are under 18, you may use this Website only with involvement of a parent or guardian. SP Group and its Affiliates reserve the right to refuse service, terminate Accounts, remove or edit content, or cancel applications in their sole discretion.',
      "You are responsible for all actions taken with your User ID, password and Account. Therefore we  do  not  recommend  that  you  disclose  your  User  ID,  password  and  Account  to  any  third parties. If you choose to share your User ID, password and Account or your information with third parties to provide you additional services, you are responsible for all actions taken with your User ID, password and Account and therefore you should review that third party's privacy policy. You will be responsible for the use of your Account, whether or not authorised by you. Note  that  if  you  lose  control  of  your  password,  you  may  lose  substantial  control  over  your personally identifiable information and may be subject to legally binding actions taken on your behalf.",
      'You shall immediately notify SP Group if it comes to your knowledge that your password has been compromised or there has been unauthorised use of their Account.',
      'SP Group shall not be liable for any damage or loss of any kind suffered by you as a result of unauthorised use of your User ID, password and Account.',
    ],
  },
  {
    title: 'ELECTRONIC COMMUNICATIONS',
    contents: [
      'When you visit this Website or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by  e-mail  or  by  posting  notices  on  this  Website.  You  agree  that  all  agreements,  notices, disclosures  and  other  communications  that we  provide  to  you electronically  satisfy  any  legal requirement that such communications be in writing.',
    ],
  },
  {
    title: 'ELECTRONIC FORM',
    contents: [
      'You agree that these Terms and Conditions in electronic form constitute a written document and therefore you undertake not to dispute or challenge the validity or enforceability of these Terms and Conditions on the grounds that it is not a written document and you hereby waive any such right you may have at law.',
    ],
  },
  {
    title: 'WEBSITE POLICIES, MODIFICATION, AND SEVERABILITY',
    contents: [
      'Please  review  our  other  policies,  such  as  the  SP  Group  Personal  Data  Protection  Policy (https://www.spgroup.com.sg/personal-data-protection-policy)    which    shall    be    deemed incorporated  into  these  Terms  and  Conditions,  and  the  Privacy  Policy,  Disclaimer  and Indemnity  posted  on  this  Website.  These  policies  also  govern  your  visit  to  this  Website.  We reserve the right to make changes to our Website, policies, and these Terms and Conditions at any  time.  If  any  of  these  conditions  shall  be  deemed  invalid,  void,  or  for  any  reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining conditions.',
      {
        subtitle:
          'Without prejudice to the generality of the foregoing, if and when you make payment, SP Group may collect, use and disclose to any third party, including debit/credit card companies, banks and payment gateways any and all particulars relating to your personal information, in accordance with the Personal Data Protection Act 2012 and SP Group Personal Data Protection Policy, for the purposes of',
        subContent: [
          '<span>(a)</span>	providing, facilitating and/or processing the present or subsequent transactions,',
          '<span>(b)</span> billing and account management (including debt collection or recovery);',
          '<span>(c)</span>	conducting surveys or obtaining feedback;',
          "<span>(d)</span>	informing you of your or your related entities' and business affiliates' services and offers (unless you duly inform SP Group otherwise); and",
          '<span>(e)</span>	complying with all applicable laws and regulations, and business requirements.',
        ],
      },
      {
        subtitle:
          'When you pay for third party services, SP Group only receives money from you as part of a collection service SP Group provides. In receiving such money, SP Group is not providing and does not undertake to provide any payment services to you. While SP may provide an option for you to send money to SP Group via a designated online payment gateway (for the purposes of SP Group’s provision of collection service), SP Group does not:',
        subContent: [
          '<span>(a)</span>	Provide to you the service of accepting money for the purpose of executing, arranging for the execution of a payment transaction (including but not limited to a domestic money transfer service as defined under the Payment Services Act 2019 (No. 2 of 2019);',
          '<span>(b)</span> Provide the online payment gateway or related services; and',
          '<span>(c)</span>	Purport to operate a payment service (provided to me) regulated under the Payment Services Act (No. 2 of 2019).',
          'You shall be deemed to be the party making such payment directly to the relevant third-party service provided, regardless of the payment method, how the payment is processed or transmitted or how the relevant third party service provider receives the payment.',
          'Consequently, SP Group owes no duties and does not purpose to owe any such duties (whether contractual or otherwise) to you in relation to the receipt of such money. You agree not to claim against SP Group or any of its agent for any failure, disruption or error in connection with making payment or your chosen payment method. You acknowledge that SP has the right at any time to modify or discontinue, temporarily or permanently, any payment method without notice to you or giving any reason.',
          'You acknowledge that no member of the SP Group is licensed, approved or registered under the Payment Services Act 2019 (No. 2 of 2019) and you may not be afforded the relevant protections set out in the Payment Services Act 2019 (No. 2 of 2019) and related subsidiary legislation, regulations, circulars, notices, directions and guidelines.',
        ],
      },
    ],
  },
  {
    title: 'INDEMNITY',
    contents: [
      {
        subtitle:
          'You  hereby  agree  to  indemnify  and  hold  SP  Group  harmless  against  all  damages,  losses, expenses and costs (including legal costs) suffered or incurred by SP Group in connection with or arising from:',
        subContent: [
          '<span>(a)</span> your access to this Website and/or the use of the online services;',
          '<span>(b)</span> any other party’s access of this Website and/or use of the online services using your User ID and/or login password;',
          '<span>(c)</span> your breach of any of these Terms and Conditions; and/or',
          '<span>(d)</span> any other party’s breach of these Terms and Conditions where such party was able to access this Website and/or use the online services by using your User ID and/or login password.',
        ],
      },
    ],
  },
  {
    title: 'PRIVACY POLICY',
    contents: [
      'Your privacy is important to SP Group. We are committed to maintaining the confidentiality of the personal information that you give us through this Website and/or by accessing and using our online services.',
      'We collect personal information from you for the purposes of providing you with and improving SP  Group’s  services.  We  use  information  in  the  file  we  maintain  about  you,  and  other information we obtain from your current and past activities on the Website to resolve disputes and troubleshoot problems. At times, we may look across multiple users to identify problems or resolve  disputes,  and  in  particular  we  may  examine  your  information  to  identify  users  using multiple User IDs or aliases, if any.',
      'Only authorised staff will have access to your personal information.',
      'To serve you well, we may from time to time work with other organisations including SP Group’s affiliates to offer other products and services to you.',
      "You  agree  that  we  may  use  personally  identifiable  information  about  you  to  improve  our marketing  and  promotional  efforts,  to  analyze  website  usage,  improve  our  content,  product offerings and services, and customize our Website's content, layout, and services. This may include using your information to contact you and deliver information to you that is targeted to your  interests,  such  as  targeted  banner  advertisements,  administrative  notices,  product offerings and communications relevant to your use of the Website.",
      'Like most websites, we may use cookies to "remember" information about your preferences. You can set up your web browser to accept or reject cookies.',
      'If  you  send  us  personal  correspondence,  such  as  emails  or  letters,  or  if  other  users  or  third parties  send  us  correspondence  about  your  activities  or  postings  on  the  Website,  we  may collect such information into a file specific to you.',
      'We use industry standard practices to safeguard the confidentiality of your personal identifiable information.  SP  Group  treats  data  as  an  asset  that  must  be  protected  against  loss  and unauthorised access. We employ many different security techniques to protect such data from unauthorised  access  by  users  inside  and  outside  our  group  of  companies.  However,  please note  that  we  cannot  ensure  that  all  of  your  private  communications  and  other  personally identifiable information will not be disclosed in ways not otherwise described in  these Terms and Conditions and/or the SP Group Personal Data Protection Policy (for example, in disclosing your  personal  data  to  law  enforcement,  regulatory  or  other  government  agencies,  or  to  third parties   under   certain   circumstances,   third   parties   may   unlawfully   intercept   or   access transmissions or private communications, or users may abuse or misuse your information that they collect from our Website).',
      'SP Group may disclose your personal data to law enforcement, regulatory or other government agencies,  or  to  third  parties.  For  example,  certain  aggregate  information  contained  in  your registration  application  or  account  may  be  disclosed  when  we  believe  that  the  disclosure  is required  to  comply  with  the  law;  enforce  or  apply  our  Terms  and  Conditions  and  other agreements and/or policies (including the SP Group Personal Data Protection Policy); protect the  rights,  property  or  safety  of  our  Website,  our  users  or  others.  The  foregoing  includes exchanging information with other companies and organizations for fraud protection and credit risk  reduction.  Please  note  that  this  does  not  include  selling,  renting,  sharing,  or  otherwise disclosing personally identifiable information of customers for commercial purposes in violation of  the  commitments  set  forth  in  these  Terms  and  Conditions  and/or  the  SP  Group  Personal Data Protection Policy.',
      {
        subtitle:
          'With respect to the collection, use and disclosure of your personal data, SP Group has prepared the SP Group Personal Data Protection Policy which explains what personal data SP Group collects and the purposes for which your personal data will be collected, used and disclosed by SP Group. The SP Group Personal Data Protection Policy, which shall be deemed incorporated into these Terms and Conditions, is available here. In the event of any inconsistency between the terms set out in these Terms and Conditions and the SP Group Personal Data Protection Policy and in so far as it relates to the collection, use and disclosure of personal data, the terms set out in the SP Group Personal Data Protection Policy shall prevail. If you have any questions, comments or requests for information regarding your personal data, please contact our SP Data Protection Office at:',
        extraContent: [
          'SP Data Protection Office',
          'SP Group',
          '2 Kallang Sector',
          'Singapore 349277',
          'Email: dpo@spgroup.com.sg',
        ],
      },
    ],
  },
  {
    title: 'Amendments to these Terms and Conditions',
    contents: [
      'SP Group reserves the right to amend these Terms and Conditions from time to time without prior notice to you. Should there be any amendments, the revised terms and conditions will be posted on this Website. By continuing to use this Website after such changes have been made, you acknowledge and agree you have read, understood and accept the changes.',
    ],
  },
]
const ValidationSchema = object().shape({
  'cs-veri-appid': string()
    .required()
    .label('Application Number')
    .default('')
    .max(10)
    .matches(/^[0-9]*$/g, 'Please enter numeric values only.'),
  'cs-veri-otp': string()
    .when('otp-sent', {
      is: true,
      then: string()
        .required()
        .min(5, 'OTP must be 5 digits')
        .max(5, 'OTP must be 5 digits'),
      otherwise: string().notRequired(),
    })
    .label('OTP Verification')
    .default(''),
  'cs-veri-captcha': string().when('otp-sent', {
    is: false,
    then: string().required(),
    otherwise: string().notRequired(),
  }),
  'cs-terms-conditions': boolean()
    .required()
    .oneOf([true]),
  'otp-sent': boolean().required(),
})
const Verification = ({ connectionService, isMobile, platform, dispatch }) => {
  const [isResenOtp, setIsResendOtp] = useState(false)
  const [isOpenPopup, setOpenPopup] = useState(false)

  const _lmButton = (btnType, isMobile) => {
    if (btnType === 'submit') {
      return classnames({ 'is-mobile': isMobile })
    }
    return classnames('btn--mimo btn-back lm--button lm--button--secondary', {
      'is-mobile': isMobile,
    })
  }

  const history = useHistory()
  const form = useRef(null)

  const memoizedInitialValues = useMemo(() => {
    return connectionService.data
      ? {
          'cs-veri-appid': connectionService.data?.application_id || '',
          'cs-veri-otp': '',
          'otp-checksum': connectionService.data?.otp.checksum || '',
          'otp-sent': connectionService.request_otp_success,
        }
      : ValidationSchema.default()
  }, [connectionService])

  const renderNote = () => (
    <Tooltip>
      Your 10 digit application number starts with 1, and can be found in the
      email correspondence from SP Group, such as Application Acknowledgement or
      Quotation
    </Tooltip>
  )

  const isOtpEnabled = () => {
    return (
      isResenOtp ||
      (!!connectionService.data.application_id &&
        connectionService.request_otp_success)
    )
  }

  const requestForOtp = values => {
    const { setFieldValue } = form.current || {}
    const payload = {
      application_id: values['cs-veri-appid'],
      captcha: values['cs-veri-captcha'],
    }
    setFieldValue('cs-veri-otp', '')
    setFieldValue('otp-sent', false)
    dispatch(ConnServActions.REQUEST_OTP(payload))
  }

  const resendOtp = () => {
    const { values, setFieldValue } = form.current || {}
    setIsResendOtp(true)
    setFieldValue('cs-veri-otp', '')
    setFieldValue('otp-sent', false)
    const csVeriCaptcha = values['cs-veri-captcha']
    const params = {
      'cs-veri-appid': connectionService.data.application_id,
      'cs-veri-captcha': csVeriCaptcha,
    }
    requestForOtp(params)
  }

  const verifyAppIdWithOtp = values => {
    const { setFieldTouched } = form.current || {}
    const payload = {
      application_id: values['cs-veri-appid'],
      prefix: connectionService.data.otp.checksum,
      otp: values['cs-veri-otp'],
      premiseType: values.premiseType,
    }
    setFieldTouched(['cs-veri-appid'], false)
    dispatch(ConnServActions.VERIFY_OTP(payload))
  }

  const handleErrorDialog = act => () => {
    dispatch(ConnServActions.ERROR_CONTINUE())
    history.push('/supply-connection')
  }

  const handleSubmit = values => {
    const { setFieldTouched } = form.current || {}
    const {
      location: { search },
    } = history
    const queries = Utils.parseQueryParams(search)

    if (queries && queries.mode === 'make-payment') {
      setFieldTouched(['cs-veri-appid'], false)
      dispatch(
        ConnServActions.MAKE_PAYMENT({
          application_id: values['cs-veri-appid'],
        })
      )
    } else {
      if (isOtpEnabled()) {
        verifyAppIdWithOtp({
          ...values,
          premiseType: queries.premiseType,
        })
      } else {
        requestForOtp(values)
      }
    }
  }

  const proceed = useCallback(() => {
    if (
      connectionService.verify_otp_success ||
      connectionService.mode === 'make-payment'
    ) {
      history.push('/supply-connection/status')
    }
  }, [connectionService.mode, connectionService.verify_otp_success, history])

  const handleClosePopup = () => {
    setOpenPopup(false)
  }

  useEffect(() => {
    const { setFieldValue, setFieldTouched } = form.current || {}
    setFieldValue('otp-sent', connectionService.request_otp_success)
    setFieldTouched('cs-veri-otp', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionService.request_otp_success])

  useEffect(() => {
    if (
      connectionService.request_otp_fail ||
      connectionService.request_otp_success ||
      connectionService.verify_otp_fail ||
      connectionService.verify_otp_success ||
      connectionService.mode === 'make-payment'
    ) {
      if (form.current) {
        const timeout = setTimeout(() => {
          if (
            connectionService.verify_otp_success ||
            connectionService.mode === 'make-payment'
          ) {
            proceed()
          } else {
            form.current.setSubmitting(false)
          }
        }, 1000)

        return () => {
          clearTimeout(timeout)
        }
      }
    }
  }, [connectionService, proceed])

  useEffect(() => {
    const {
      location: { search },
    } = history
    const queries = Utils.parseQueryParams(search)

    if (
      !queries ||
      !queries.mode ||
      !Object.getOwnPropertyNames(homeConnectionMode).includes(queries.mode)
    ) {
      history.push('/supply-connection')
    }
  }, [history])

  useEffect(() => {
    dispatch(ConnServActions.RESET())
  }, [dispatch])

  return (
    <Page platform={platform}>
      {!isMobile && <PageHeader title="SP Grid Connection Portal" />}
      <Formik
        validateOnMount
        validationSchema={ValidationSchema}
        initialValues={memoizedInitialValues}
        onSubmit={handleSubmit}
        innerRef={form}
      >
        <Form className="cui-form">
          <FormInput
            spec={{
              id: 'cs-veri-appid',
              label: 'Application Number',
              type: 'text',
              disabled: isOtpEnabled(),
              maxLength: 10,
            }}
            note={renderNote()}
          >
            Please enter your 10 numeric application number
          </FormInput>

          {!isOtpEnabled() && (
            <FormCheckbox
              spec={{
                id: 'cs-terms-conditions',
                type: 'checkbox',
                label: '',
              }}
            >
              I agree to the{' '}
              <span className="btn-more" onClick={() => setOpenPopup(true)}>
                Terms and Conditions
              </span>{' '}
              of SP Grid Connection Portal.
            </FormCheckbox>
          )}
          {isOtpEnabled() && (
            <FormOTP
              id="cs-veri-otp"
              name="cs-veri-otp"
              label="OTP Verification"
              preOtp={connectionService.data?.otp?.checksum}
              endingRecepient={connectionService.data?.recipient}
              resendHandler={resendOtp}
              timeout={connectionService.data?.otp?.expiresIn}
            />
          )}
          {!isOtpEnabled() && (
            <FormReCAPTCHA
              id="cs-veri-captcha"
              sitekey={config.vendor.google.recaptcha}
            />
          )}
          <FormCTA
            isMobile={isMobile}
            style={isMobile ? { maxWidth: '100%' } : {}}
          >
            <a
              href={help.getLandingUrl(platform)}
              className={_lmButton('cancel', isMobile)}
            >
              Cancel
            </a>
            <Button type="submit" className={_lmButton('submit', isMobile)}>
              {isOtpEnabled() ? 'Proceed' : 'Submit'}
            </Button>
          </FormCTA>
        </Form>
      </Formik>
      <ConfirmationDialog
        visible={!!connectionService.error}
        confirmOkHandler={handleErrorDialog('ok')}
        confirmCancelHandler={handleErrorDialog('cancel')}
        processing={false}
        actionConfig={{
          ok: {
            label: 'Continue',
          },
          cancel: false,
        }}
        content={
          connectionService.error && (
            <>
              <figure className="alert-icon">
                <Icons.fail width={isMobile ? 180 : 240} />
              </figure>
              {isMobile ? (
                <>
                  {connectionService.error &&
                  connectionService.error.length > 100 ? (
                    <h5
                      dangerouslySetInnerHTML={{
                        __html: connectionService.error,
                      }}
                    />
                  ) : (
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: connectionService.error,
                      }}
                    />
                  )}
                </>
              ) : (
                <h3
                  dangerouslySetInnerHTML={{ __html: connectionService.error }}
                />
              )}
            </>
          )
        }
      />
      <Modal
        visible={isOpenPopup}
        onClose={handleClosePopup}
        className="tnc-modal"
        data-testid="tnc-modal"
      >
        <div className="tnc-modal__wrapper">
          <div className="tnc-modal__content">
            <h4>Terms & Conditions of Use</h4>
            <p className="tnc-subtitle">
              Thank you for visiting the SP Grid Connection Portal website of
              the Singapore Power Group (the <strong>“Website”</strong>).
            </p>
            <p>
              PLEASE READ THESE TERMS AND CONDITIONS OF USE (“
              <strong>TERMS AND CONDITIONS</strong>”) CAREFULLY. BY ACCESSING
              THIS WEBSITE AND/OR USING THE ONLINE SERVICES, YOU AGREE TO BE
              BOUND BY THE FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT ACCEPT
              ANY OF THESE TERMS AND CONDITIONS, YOU MUST IMMEDIATELY
              DISCONTINUE YOUR ACCESS OF THIS WEBSITE AND/OR USE OF THE ONLINE
              SERVICES.
            </p>
            <ul className="tnc-list">
              {tncContent.map((item, idx) => (
                <li key={idx} className="tnc-item">
                  <div className="tnc-item__title">
                    <span className="order-numb">{idx + 1}</span>
                    <span>{item.title}</span>
                  </div>
                  {
                    <ul className="tnc-sublist">
                      {item.contents.map((content, index) => (
                        <li key={index} className="tnc-subitem">
                          <div className="subitem-wrapper">
                            <span className="order-numb">{`${idx + 1}.${index +
                              1}`}</span>
                            <span>{content.subtitle || content}</span>
                          </div>

                          {content.subContent ? (
                            <div className="tnc-subcontent">
                              {content.subContent.map(sub => (
                                <>
                                  <span
                                    key={`${sub + 1}`}
                                    dangerouslySetInnerHTML={{
                                      __html: `${sub}`,
                                    }}
                                  />
                                  <br />
                                </>
                              ))}
                            </div>
                          ) : (
                            <div className="tnc-extracontent">
                              {content.extraContent &&
                                content.extraContent.map(content => (
                                  <>
                                    <span key={`${content + 1}`}>
                                      {content}
                                    </span>
                                    <br />
                                  </>
                                ))}
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </li>
              ))}
            </ul>
            <button className="tnc-modal__close" onClick={handleClosePopup}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </Page>
  )
}

const mapStateToProps = state => ({
  connectionService: state.connectionService,
  platform: state.ctx.platform,
  isMobile: PLATFORM.mobile === state.ctx.platform,
})

export default connect(mapStateToProps)(Verification)
