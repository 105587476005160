import React from 'react'
import PropTypes from 'prop-types'
import UtilCol, { utilLabels } from './UtilColumn'

export default function ApplicationStatus({ utilities, type }) {
  const label = {
    open: { date: 'Turn on' },
    close: { date: 'Termination' },
  }
  return (
    <div className="status-figure">
      <div className="state-header">
        <div className="status-figure--title" />
        {Object.values(utilLabels.status).map(function(desc, id) {
          return (
            <div key={`state-${id}`} className="state">
              {desc}
            </div>
          )
        })}
        <div className="state">{label[type].date} Date*</div>
      </div>
      <div className="state-details utilities">
        {utilities.map(data => (
          <UtilCol key={`col-${data.type}`} data={data} />
        ))}
      </div>
    </div>
  )
}

ApplicationStatus.propTypes = {
  type: PropTypes.string,
  utilities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      status: PropTypes.string,
      turnOnDate: PropTypes.string,
    })
  ),
}
