import React from 'react'

import './styles.scss'

const Maintenance = ({ maintenance, type, title }) => {
  return (
    <div className="maintenance-wrapper">
      <h1 className="maintenance-title">{title}</h1>
      <div className="maintenance-status">
        <figure className="icon">
          <img src="/images/illustration-fail.svg" alt="Error" />
        </figure>
        <p className="message">
          {maintenance[type].message || 
            "Thank you for visiting our website. We are sorry that this e-service is currently not available. We will resume services as soon as possible. Thank you for your understanding and patience."
          }
        </p>
      </div>
    </div>
  )
}

export default Maintenance
