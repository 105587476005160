import React from 'react'
import ReactDOM from 'react-dom'

import 'atlas'
import 'atlas/dist/atlas.bundle.css'
import './index.scss'
import Root from './containers/Root'
import { createBrowserHistory } from 'history'
import configStore from './redux/create'
import CasperskyApi from './api/caspersky'
import axios from 'axios'
import config from './config'
import WebApi from './api/web'

// Bootstrap
const webApi = WebApi(window)
/**
 * API
 * @type {{storage: StoreFactory, caspersky: CasperskyApi}}
 */
const api = {
  conn: CasperskyApi(axios, config.api.conn),
  storage: webApi.storage,
  ga: window.dataLayer,
}
const history = createBrowserHistory()
const preloadState = api.storage.state.get()
const store = configStore(history, api, preloadState)

// Hooray
ReactDOM.render(
  <Root history={history} store={store} />,
  document.getElementById('root')
)
