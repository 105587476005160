import React from 'react'
import { connect } from 'react-redux'

import Page from 'spd-mimo/components/Page'
import Survey from 'spd-mimo/components/Survey'
import { PLATFORM } from 'spd-mimo/constants'

const Feedback = ({ connectionService, platform }) => {
  return (
    <Page platform={platform}>
      <Survey
        isMobile={PLATFORM.mobile === platform}
        miscData={{
          applicationId: connectionService.data.application_id,
        }}
      >
        <Survey.Question
          id="question1"
          type="radio"
          question="I can track the progress of my application in the SP Grid Connection Portal."
          isCrosswise
        >
          <Survey.Answer label="Strongly Agree" value="5" />
          <Survey.Answer label="Agree" value="4" />
          <Survey.Answer label="Neutral" value="3" />
          <Survey.Answer label="Disagree" value="2" />
          <Survey.Answer label="Strongly Disagree" value="1" />
        </Survey.Question>

        <Survey.Question
          id="question2"
          type="radio"
          question="It is easy to find the information I need in the portal."
        >
          <Survey.Answer label="Strongly Agree" value="5" />
          <Survey.Answer label="Agree" value="4" />
          <Survey.Answer label="Neutral" value="3" />
          <Survey.Answer label="Disagree" value="2" />
          <Survey.Answer label="Strongly Disagree" value="1" />
        </Survey.Question>

        <Survey.Question
          id="question3"
          type="radio"
          question="I am able to get in touch with SP Group if I have queries about my application."
        >
          <Survey.Answer label="Strongly Agree" value="5" />
          <Survey.Answer label="Agree" value="4" />
          <Survey.Answer label="Neutral" value="3" />
          <Survey.Answer label="Disagree" value="2" />
          <Survey.Answer label="Strongly Disagree" value="1" />
        </Survey.Question>

        <Survey.Question
          id="question4"
          type="radio"
          question="Overall, the portal has made the entire electricity supply connection process smoother."
        >
          <Survey.Answer label="Strongly Agree" value="5" />
          <Survey.Answer label="Agree" value="4" />
          <Survey.Answer label="Neutral" value="3" />
          <Survey.Answer label="Disagree" value="2" />
          <Survey.Answer label="Strongly Disagree" value="1" />
        </Survey.Question>

        <Survey.Question
          id="question5"
          type="textarea"
          question="Any comment or suggestion for improvement?"
          optional
        >
          <Survey.Answer value="Text input" max={1000} />
        </Survey.Question>
      </Survey>
    </Page>
  )
}

const mapStateToProps = state => ({
  connectionService: state.connectionService,
  platform: state.ctx.platform,
})

export default connect(mapStateToProps)(Feedback)
