import React from 'react'

import PaymentDetail from './PaymentDetail'

const PaymentDetails = props => {
  const { paymentStatus, quotation } = props

  return (
    <>
      <PaymentDetail.Wrap>
        <PaymentDetail.Section>
          <PaymentDetail.Status
            label="Transaction Details"
            subLabel="Grid Connection Portal"
            status={paymentStatus.status === 'PAYMENT_SUCCESS'}
          />
        </PaymentDetail.Section>
        <PaymentDetail.Section className="invoice">
          <PaymentDetail.Invoice
            label="Date/Time"
            detail={paymentStatus.paymentTxnDate}
          />
          <PaymentDetail.Invoice
            label="Paid Via"
            detail={paymentStatus.paymentMode}
          />
          <PaymentDetail.Invoice
            label="Transaction Number"
            detail={paymentStatus.txnRef}
          />
          <PaymentDetail.Invoice
            label="Quotation Number"
            detail={paymentStatus.quotNo}
          />
        </PaymentDetail.Section>
        <PaymentDetail.Section className="summary">
          <PaymentDetail.Summary
            label="Amount"
            subLabel="(Inclusive of GST)"
            amount={paymentStatus.paymentAmt || quotation.payable}
          />
        </PaymentDetail.Section>
      </PaymentDetail.Wrap>
      {paymentStatus.status === 'PAYMENT_SUCCESS' && (
        <PaymentDetail.Note
          message={
            'Thank you for your payment. The tax invoice will be send to the email listed in the application.'
          }
        />
      )}
    </>
  )
}

export default PaymentDetails
