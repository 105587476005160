import axios from 'axios'
import config from 'spd-mimo/config'
import { prop } from 'ramda'

const requestService = (payload, isAuth = true) => {
  const options = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  }

  if (isAuth) {
    const connServTkn = window.sessionStorage.getItem('connserv:tkn')
    if (connServTkn) {
      const tkn = JSON.parse(connServTkn)
      // options.withCredentials = true
      options.headers = {
        ...options.headers,
        Authorization: `${tkn.token}`,
      }
    }
  }

  return new Promise((resolve, reject) => {
    axios(options)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          resolve(res)
        }
      })
      .catch(err => {
        if (err.response) {
          reject(err.response)
        } else {
          reject('Error occured')
        }
      })
  })
}

const RequestOtp = (params = {}) => {
  return new Promise((resolve, reject) => {
    requestService(
      {
        url: params.url,
        method: 'POST',
        data: {
          client: 'CONN',
          ...params.payload,
        },
      },
      false
    )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

const VerifyOtp = (params = {}) => {
  return new Promise((resolve, reject) => {
    requestService({
      url: params.url,
      method: 'POST',
      data: params.payload,
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

const GetStatus = (params = {}) => {
  return new Promise((resolve, reject) => {
    requestService({
      url: params.url,
      method: 'GET',
    })
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

const GetStatusWithoutOTP = (params = {}) => {
  return new Promise((resolve, reject) => {
    requestService(
      {
        url: params.url,
        method: 'POST',
        data: params.payload,
      },
      false
    )
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

const SubmitPayment = (params = {}) => {
  return new Promise((resolve, reject) => {
    requestService({
      url: params.url,
      method: 'POST',
      data: params.payload,
    })
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

const getData = prop('data')

/**
 * @param {{khaosTxnId: string, sdTxnRef: string, appId: number}} data
 * @returns {Promise}
 */
function ProcessPayment(data) {
  return requestService({
    url: `${config.api.conn.url}/payment/khaos/process`,
    method: 'POST',
    data,
  }).then(getData)
}

export default {
  RequestOtp,
  VerifyOtp,
  GetStatus,
  GetStatusWithoutOTP,
  SubmitPayment,
  ProcessPayment,
}
