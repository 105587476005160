import Constants from './constants'

const CONNSERV_DATA_SESSION_KEY = 'connserv:data'

const defaultState = {
  request_otp: false,
  request_otp_success: false,
  request_otp_fail: false,
  verify_otp: false,
  verify_otp_success: false,
  verify_otp_fail: false,
  get_status: false,
  get_status_success: false,
  get_status_fail: false,
  submit_payment: false,
  submit_payment_success: false,
  submit_payment_fail: false,
  get_payment_status: false,
  get_payment_status_success: false,
  get_payment_status_fail: false,
  error: null,
  data: {
    token: null,
    application_id: null,
    recipient: '',
    otp: {
      checksum: '',
      expiresIn: 120,
    },
    /**
     * Status payload
     * premiseAddress
     * message
     * lewName
     * lewMobile
     * milestone
     * quotation : {
     *  amount,
     *  sentDate,
     *  paidDate
     * }
     */
    // status: null,
    status: {
      premiseAddress: '123 Abc St Singapore 456123',
      message: 'The site is ready to start cable installation works. Good.',
      lewName: 'John Doe',
      lewMobile: '+65 9111111',
      quotation: {
        amount: 523.21,
        sentDate: '2020-02-11',
        paidDate: '2019-12-17',
      },
      projectOfficer: {
        name: 'Jane Doe',
        email: 'test@email.com',
        mobileNo: '+65 9320483',
      },
    },
  },
}

const getInitialData = () => {
  const data = window.sessionStorage.getItem(CONNSERV_DATA_SESSION_KEY)
  if (data) {
    return JSON.parse(data)
  }
  return defaultState.data
}

export const initialState = {
  ...defaultState,
  data: getInitialData(),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Constants.REQUEST_OTP: {
      return {
        ...state,
        request_otp: true,
        request_otp_success: false,
        request_otp_fail: false,
        mode: 'check-status',
        error: null,
      }
    }

    case Constants.REQUEST_OTP_SUCCESS: {
      return {
        ...state,
        request_otp: false,
        request_otp_success: true,
        request_otp_fail: false,
        error: null,
        data: action.payload,
      }
    }

    case Constants.REQUEST_OTP_FAIL: {
      return {
        ...state,
        request_otp: false,
        request_otp_success: false,
        request_otp_fail: true,
        error: action.payload,
      }
    }

    case Constants.VERIFY_OTP: {
      return {
        ...state,
        request_otp: false,
        verify_otp: true,
        verify_otp_success: false,
        verify_otp_fail: false,
        error: null,
      }
    }

    case Constants.VERIFY_OTP_SUCCESS: {
      const data = {
        ...state.data,
        application_id: action.payload.application_id,
      }
      window.sessionStorage.setItem(
        CONNSERV_DATA_SESSION_KEY,
        JSON.stringify(data)
      )
      return {
        ...state,
        premiseType: action.payload.premiseType,
        verify_otp: false,
        verify_otp_success: !!action.payload.token,
        verify_otp_fail: false,
        error: null,
        data,
      }
    }

    case Constants.VERIFY_OTP_FAIL: {
      // window.sessionStorage.setItem(
      //   CONNSERV_DATA_SESSION_KEY,
      //   JSON.stringify(initialState.data)
      // )
      return {
        ...state,
        verify_otp: false,
        verify_otp_success: false,
        verify_otp_fail: true,
        error: action.payload,
        data: initialState.data,
      }
    }

    case Constants.GET_STATUS: {
      return {
        ...state,
        request_otp: false,
        request_otp_success: false,
        request_otp_fail: false,
        verify_otp: false,
        verify_otp_success: true,
        verify_otp_fail: false,
        get_status: true,
        get_status_success: false,
        get_status_fail: false,
        error: null,
      }
    }

    case Constants.GET_STATUS_SUCCESS: {
      return {
        ...state,
        get_status: false,
        get_status_success: true,
        get_status_fail: false,
        data: {
          ...state.data,
          status: action.payload,
        },
        error: null,
      }
    }

    case Constants.GET_STATUS_FAIL: {
      return {
        ...state,
        get_status: false,
        get_status_success: false,
        get_status_fail: true,
        error: action.payload,
      }
    }

    case Constants.SUBMIT_PAYMENT: {
      return {
        ...state,
        request_otp: false,
        request_otp_success: false,
        request_otp_fail: false,
        verify_otp: false,
        verify_otp_success: false,
        verify_otp_fail: false,
        get_status: false,
        get_status_success: false,
        get_status_fail: false,
        submit_payment: true,
        submit_payment_success: false,
        submit_payment_fail: false,
        error: null,
      }
    }

    case Constants.SUBMIT_PAYMENT_SUCCESS: {
      const data = {
        ...state.data,
        payment: {
          submitted_payment: true,
          payment_method: action.payload.payment_method,
        },
      }
      window.sessionStorage.setItem(
        CONNSERV_DATA_SESSION_KEY,
        JSON.stringify(data)
      )
      return {
        ...state,
        submit_payment: false,
        submit_payment_success: true,
        submit_payment_fail: false,
        data: {
          ...state.data,
          payment: action.payload,
        },
        error: null,
      }
    }

    case Constants.SUBMIT_PAYMENT_FAIL: {
      return {
        ...state,
        submit_payment: false,
        submit_payment_success: false,
        submit_payment_fail: true,
        error: action.payload,
      }
    }

    case Constants.GET_PAYMENT_STATUS: {
      window.sessionStorage.removeItem(CONNSERV_DATA_SESSION_KEY)
      return {
        ...state,
        get_payment_status: true,
        get_payment_status_success: false,
        get_payment_status_fail: false,
        data: {
          ...state.data,
          payment: {
            ...state.data.payment,
            submitted_payment: false,
          },
        },
        error: null,
      }
    }

    case Constants.GET_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        get_payment_status: false,
        get_payment_status_success: true,
        get_payment_status_fail: false,
        data: {
          ...state.data,
          payment_status: action.payload,
        },
        error: null,
      }
    }

    case Constants.GET_PAYMENT_STATUS_FAIL: {
      return {
        ...state,
        get_payment_status: false,
        get_payment_status_success: false,
        get_payment_status_fail: true,
        error: action.payload,
      }
    }

    case Constants.MAKE_PAYMENT: {
      return {
        ...state,
        mode: 'make-payment',
        data: {
          ...state.data,
          application_id: action.payload.application_id,
          status: null,
        },
      }
    }

    case Constants.ERROR_CONTINUE: {
      return {
        ...state,
        verify_otp: false,
        verify_otp_success: false,
        verify_otp_fail: false,
        get_status: false,
        get_status_success: false,
        get_status_fail: false,
        submit_payment: false,
        submit_payment_success: false,
        submit_payment_fail: false,
        error: null,
      }
    }

    case Constants.RESET: {
      return {
        ...defaultState,
      }
    }

    default:
      return {
        ...state,
      }
  }
}
