import Constants from './_constants'
import Services from './_services'

const SUBMIT = (payload = {}, url) => {
  return async dispatch => {
    dispatch({
      type: Constants.SUBMIT,
    })
    try {
      const result = await Services.SubmitSurvey({ payload, url })
      dispatch({
        type: Constants.SUBMIT_SUCCESS,
        payload: result,
      })
    } catch (err) {
      dispatch({
        type: Constants.SUBMIT_FAIL,
        payload: err,
      })
    }
  }
}

export default {
  SUBMIT,
}
