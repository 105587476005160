import React, { useContext } from 'react'

import { useScreenSize } from '../ScreenSize'
import Tabs from './Tabs'

const TabContent = ({ children }) => {
  const screenSize = useScreenSize()
  const tabsContext = useContext(Tabs.Context) || {}

  function getTitle(child, idx) {
    if (child.props.title !== '') {
      return child.props.title
    }
    if (tabsContext.items[idx]) {
      return tabsContext.items[idx].title
    }
    return ''
  }

  function renderChildren() {
    return React.Children.map(children, (child, idx) => {
      // if (idx !== 0) {
      //   if (tabsContext.static) {
      //     return null
      //   }
      // }
      if (screenSize.width > screenSize.breakpoints.MOBILE.width) {
        if (tabsContext.active !== idx) {
          return null
        }
      }
      const childProps = {
        ...child.props,
        title: getTitle(child, idx),
        index: idx,
      }
      return React.cloneElement(child, childProps)
    })
  }
  return <div className="cui-tabs__content">{renderChildren()}</div>
}

TabContent.displayName = 'CuiTabContent'

export default TabContent
