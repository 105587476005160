import Constants, { khaosPaymentMode } from './constants'
import Services from './services'
import Utils from '../../../components/common/utils'
import { PLATFORM } from 'spd-mimo/constants'

const REQUEST_OTP = payload => {
  return async dispatch => {
    dispatch({
      type: Constants.REQUEST_OTP,
    })
    const requestOtpParams = {
      url: `${Constants.API_URL}/connection/request-sms-otp`,
      payload: {
        applicationId: payload.application_id,
        captcha: payload.captcha,
      },
    }
    try {
      const result = await Services.RequestOtp(requestOtpParams)
      dispatch({
        type: Constants.REQUEST_OTP_SUCCESS,
        payload: {
          application_id: result.data.applicationId,
          recipient: result.data.mobileNo,
          otp: {
            checksum: result.data.otpPrefix,
            expiresIn: result.data.expirationInSeconds || 120,
          },
        },
      })
    } catch (err) {
      let errMessage = ''
      if (err) {
        window.sessionStorage.removeItem('connserv:tkn')
        if (err.status === Constants.SERVER_ERROR) {
          errMessage =
            "We're currently facing issues communicating your request, please try again in a few minutes."
        } else if (err && err.message) {
          errMessage = Utils.checkForErrorMessage(err)
        } else {
          errMessage = 'An error occurred'
        }
      } else {
        errMessage = 'An error occurred'
      }
      dispatch({
        type: Constants.REQUEST_OTP_FAIL,
        payload: errMessage,
      })
    }
  }
}

const VERIFY_OTP = payload => {
  return async dispatch => {
    dispatch({
      type: Constants.VERIFY_OTP,
    })
    const verifyOtpParams = {
      url: `${Constants.API_URL}/connection/verify-sms-otp`,
      payload: {
        applicationId: payload.application_id,
        prefix: payload.prefix,
        otpSubject: payload.otp,
      },
    }
    try {
      const token = await Services.VerifyOtp(verifyOtpParams)
      window.sessionStorage.setItem(
        'connserv:tkn',
        JSON.stringify({
          token: token.data,
        })
      )
      dispatch({
        type: Constants.VERIFY_OTP_SUCCESS,
        payload: {
          application_id: payload.application_id,
          token: token.data,
          premiseType: payload.premiseType,
        },
      })
    } catch (err) {
      dispatch({
        type: Constants.VERIFY_OTP_FAIL,
        payload: Utils.checkForErrorMessage(err),
      })
    }
  }
}

const GET_STATUS = payload => {
  return async dispatch => {
    dispatch({
      type: Constants.GET_STATUS,
    })

    const getStatusParams = {
      url: `${Constants.API_URL}/connection/${payload.application_id}`,
    }

    try {
      const result = await Services.GetStatus(getStatusParams)
      dispatch({
        type: Constants.GET_STATUS_SUCCESS,
        payload: result,
      })
    } catch (err) {
      dispatch({
        type: Constants.GET_STATUS_FAIL,
        payload: Utils.checkForErrorMessage(err),
      })
    }
  }
}

const GET_STATUS_WITHOUT_OTP = payload => {
  return async dispatch => {
    dispatch({
      type: Constants.GET_STATUS,
    })

    const getStatusParams = {
      url: `${Constants.API_URL}/connection`,
      payload: {
        applicationId: payload.application_id,
      },
    }

    try {
      const result = await Services.GetStatusWithoutOTP(getStatusParams)
      dispatch({
        type: Constants.GET_STATUS_SUCCESS,
        payload: result,
      })
    } catch (err) {
      dispatch({
        type: Constants.GET_STATUS_FAIL,
        payload: Utils.checkForErrorMessage(err),
      })
    }
  }
}

const SUBMIT_PAYMENT = payload => {
  return async dispatch => {
    dispatch({
      type: Constants.SUBMIT_PAYMENT,
    })
    const isKiosk = PLATFORM.kiosk === payload.platform

    try {
      const result = await Services.SubmitPayment({
        url: isKiosk
          ? `${Constants.API_URL}/payment/khaos`
          : `${Constants.API_URL}/payment`,
        payload,
      })
      dispatch({
        type: Constants.SUBMIT_PAYMENT_SUCCESS,
        payload: { ...result, payment_method: payload.mode },
      })
      if (isKiosk) {
        const searchString = new URLSearchParams({
          paymentMode: khaosPaymentMode[payload.mode],
          amount: Math.round(100 * payload.amount),
          clientId: 'SA',
          clientState: btoa(
            JSON.stringify({
              id: result.sdTxnRef,
              q: payload.quotationNo,
              a: payload.applicationId,
              r: result.receiptNo,
            })
          ),
          redirectUrl: btoa(sessionStorage.getItem('redirectUrl')),
        }).toString()
        window.location = `${sessionStorage.getItem(
          'paymentUrl'
        )}?${searchString}`
      }
    } catch (err) {
      dispatch({
        type: Constants.SUBMIT_PAYMENT_FAIL,
        payload: Utils.checkForErrorMessage(err),
      })
    }
  }
}

const GET_PAYMENT_STATUS = payload => {
  return async dispatch => {
    dispatch({
      type: Constants.GET_PAYMENT_STATUS,
    })

    const getPaymentStatusParams = {
      url: `${Constants.API_URL}/payment/${payload.txnRef}`,
    }

    try {
      const result = await Services.GetStatus(getPaymentStatusParams)
      dispatch({
        type: Constants.GET_PAYMENT_STATUS_SUCCESS,
        payload: result,
      })
    } catch (err) {
      dispatch({
        type: Constants.GET_PAYMENT_STATUS_FAIL,
        payload: Utils.checkForErrorMessage(err),
      })
    }
  }
}

function KIOSK_PAYMENT_PROCESS({ txnRef, sdTxnRef, applicationId }) {
  return function(dispatch) {
    return Services.ProcessPayment({
      khaosTxnId: txnRef,
      sdTxnRef,
      appId: applicationId,
    })
      .then(function() {
        GET_PAYMENT_STATUS({ txnRef: sdTxnRef })(dispatch)
      })
      .catch(err => {
        dispatch({
          type: Constants.GET_PAYMENT_STATUS_FAIL,
          payload: Utils.checkForErrorMessage(err),
        })
      })
  }
}

const MAKE_PAYMENT = payload => {
  return dispatch => {
    dispatch({
      type: Constants.MAKE_PAYMENT,
      payload,
    })
  }
}

const ERROR_CONTINUE = () => {
  return dispatch => {
    dispatch({
      type: Constants.ERROR_CONTINUE,
    })
  }
}

const RESET = () => {
  return dispatch => {
    dispatch({
      type: Constants.RESET,
    })
  }
}

export default {
  REQUEST_OTP,
  VERIFY_OTP,
  GET_STATUS,
  GET_STATUS_WITHOUT_OTP,
  SUBMIT_PAYMENT,
  GET_PAYMENT_STATUS,
  KIOSK_PAYMENT_PROCESS,
  MAKE_PAYMENT,
  ERROR_CONTINUE,
  RESET,
}
