import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loader from './common/Loader'
import Detail from './detail/Detail'
import ConfirmationDialog from './common/ConfirmationDialog'
import Icons from './common/Icons'

function ApplicationDetail(props) {
  useEffect(
    function() {
      props.fetchApplication(props.application.reference)
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { err, data, loading } = props.application
  return (
    <div className="application-detail">
      {loading ? (
        <Loader>
          <p>Loading...</p>
        </Loader>
      ) : data ? (
        <Detail type={props.type} data={data} />
      ) : null}
      <div className="lm--formItem lm--formItem--inline form-cta">
        <button
          onClick={props.back}
          className="btn--mimo lm--button lm--button--secondary"
        >
          Back
        </button>
      </div>

      <ConfirmationDialog
        visible={!!err}
        confirmOkHandler={props.back}
        confirmCancelHandler={props.back}
        processing={false}
        actionConfig={{
          ok: {
            label: 'Back',
          },
          cancel: false,
        }}
        content={
          <Fragment>
            <figure className="alert-icon">
              <Icons.fail width={240} />
            </figure>
            <h3>{err}</h3>
          </Fragment>
        }
      />
    </div>
  )
}
ApplicationDetail.propTypes = {
  type: PropTypes.string,
  back: PropTypes.func,
  fetchApplication: PropTypes.func,
  application: PropTypes.shape({
    data: PropTypes.object,
    reference: PropTypes.object,
    error: PropTypes.string,
    loading: PropTypes.bool,
  }),
}

export default ApplicationDetail
